import React, {useEffect, useState} from "react";
import {TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText} from "@material-ui/core";
  import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ExpiredMessage = (props) => {
    console.log(props)
    const [open, setOpen] = React.useState(props.expiredMsg);

 /*  const handleClickOpen = () => {
    setOpen(true);
  }; */

  const handleClose = () => {
    setOpen(false);
    props.setexpiredLogin(true);
  };

  return (
    <div>
     {/*  <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Session Expired"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          You have been logged out due to session expiry. Please log back in with your credentials to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ExpiredMessage;