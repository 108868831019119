import React, { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
  Link as NavigationLink,
} from "react-router-dom";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Popover
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import API from "../../api";
import "../admin/screens/LoginScreen.css";
import clsx from "clsx";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import oc_logo from "../../assets/images/omnicure_logo.svg";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import HomeIcon from '@material-ui/icons/Home';
import ExpiredMessage from "../common/ExpiredMessage";
import axios from 'axios';
import createAuthRefreshInterceptor from "axios-auth-refresh";
const usePasswordStyles = makeStyles({
  error: {
    whiteSpace: "pre-wrap",
  },
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
  partialCorrect: {
    "& label.Mui-focused": {
      color: "yellow",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "yellow",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "yellow",
      },
    },
  },
  wrong: {
    "& label.Mui-focused": {
      color: "red",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "red",
      },
    },
  },
  ullist: {
    ".MuiList-padding": {
      padding: 0,
    },
    "& .MuiListItem-root": {
      padding: 0,
    },
    "& .MuiTypography-body1": {
      fontSize: "small",
      lineHeight: 1,
      color: "red",
    },
  },
  btndisable: {
    backgroundColor: "#ebebeb",
    cursor: " not-allowed !important",
    pointerEvents: "none !important",
    /* '&:hover': {
        backgroundColor: "#ebebeb",
        cursor:' not-allowed',
        pointerEvents: 'none'
    } */
  },
  toolBar:{
    display:'flex',
    justifyContent:'space-between'
  },
  
  iconStyle: {
    fontSize: "2rem",
    marginRight: "10px",
    // margin: '5px 0px 5px 5px'
  },
  menuList: {
    padding: "0px 8px !important",
  },
});

const alphabets = "abcdefghijklmnopqrstuvwxyz";

const passwordExceptionWords = [
  "qwerty",
  "asdfgh",
  "zxcvbn",
  "password",
  "admin",
  "test",
];

function ChangePasswordScreen({ setUserId, userId, userDetails, setUserDetails, setApp, tokenId, setTokenId, refreshId, setRefreshId}) {
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [passwordNew, setPasswordNew] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { from } = location.state || { from: null };
  const [status, setStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickConfirmShowPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseConfirmDownPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleClickOldShowPassword = () => setShowOldPassword(!showOldPassword);
  const handleMouseOldDownPassword = () => setShowOldPassword(!showOldPassword);
  const [passField, setPassValue] = React.useReducer(passwordReducer, {
    Error: false,
    Label: "New Password",
    value: "",
    helperText: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [popup,setPopup] = useState(null);
  const mobileScreen = useMediaQuery(`(max-width:636px)`);
  const [expiredMsg, setExpiredMsg] = useState(false);
  const [expiredLogin, setexpiredLogin] = useState(false);
  
  const retryApiGet = (url,header)=>{
    return API.get(url,header).catch(function(error){console.log(error,error.response,"Retry Fetch");if([401,500,502,504,703,704].includes(error?.response?.status)){
      return new Promise(resolve=>{
        API.post("/userEndpoints/v1/renewIdToken",{
          "token": refreshId
        })
        .then((res)=>{
          setTokenId(res.data.idToken)
          if(!res.data.idToken){
            setExpiredMsg(true)
            if(expiredLogin){
              setUserId(null)
              history.push("/")
            }
            throw error
          }else{
            resolve(API.get(url,{headers:{
              Authorization:res.data.idToken
            }}))
          }
        })
        .catch((error) =>{Promise.reject(error)})
      })
    }})
  }
  
  const retryApiPost = (url,data,header)=>{
    return API.post(url,data,header).catch(function(error){console.log(error,error.response,"Retry Fetch");if([401,500,502,504,703,704].includes(error?.response?.status)){
      return new Promise(resolve=>{
        API.post("/userEndpoints/v1/renewIdToken",{
          "token": refreshId
        })
        .then((res)=>{
          setTokenId(res.data.idToken)
          if(!res.data.idToken){
            setExpiredMsg(true)
            if(expiredLogin){
              setUserId(null)
              history.push("/")
            }
            throw error
          }else{
            resolve(API.post(url,data,{headers:{
              Authorization:res.data.idToken
            }}))
          }
        })
        .catch((error) =>{Promise.reject(error)})
      })
    }})
  }

  const signOut = () => {
    //localStorage.removeItem('userId','userDetails','app','userId')
    setUserId(null);
    setUserDetails({});
    setApp(null);
    history.replace("/");
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }
  const changePasswordScreen = () => {
    history.push("/app/changePassword");
  }

  const refreshToken = () => {
    API.post("/userEndpoints/v1/renewIdToken",{
      "token": refreshId
    })
    .then((res)=>{
      setTokenId(res.data.idToken)
      if(!res.data.idToken){
        setExpiredMsg(true)
        if(expiredLogin){
          setUserId(null)
          history.push("/")
        }
      }
    })
    .catch((error) =>{})
  }
  // useEffect(()=>{
  //   const refreshAuthLogic = failedRequest =>
  //   API.post("/userEndpoints/v1/renewIdToken",{   
  //     "token": refreshId
  //   })
  //   .then((res)=>{
  //     setTokenId(res.data.idToken)
  //     if(!res.data.idToken){
  //       setExpiredMsg(true)
  //       if(expiredLogin){
  //         setUserId(null)
  //         history.push("/")
  //         return Promise.reject();
  //       }
  //       return Promise.reject();
  //     }
  //     failedRequest.response.config.headers["Authorization"] = res.data.idToken;
  //   return Promise.resolve();
  //   })
  //   .catch((error) =>{
  //     console.log("refresh fail");
  //     return Promise.reject(error);
  //   })
  //   createAuthRefreshInterceptor(API, refreshAuthLogic,{statusCodes:[401,500,502,504,703,704]});
  // },[])
  useEffect(() =>{
    if(expiredLogin){
      setUserId(null)
      history.push("/")
    }
  },[expiredLogin])
  useEffect(() => {
    passField.value && setPassValue({ value: passField.value });
  }, [userId]);
  const passwordStyles = usePasswordStyles();
  const submit = () => {
    if (passField.value === confirmPassword) {
      retryApiPost("/adminUserEndpoints/v1/resetPassword", {
        email: userId,
        oldPassword: oldPassword,
        passwordNew: passField.value,
        confirmPassword: confirmPassword,
        // send the app name too
      },
      {
        headers: {
          'Authorization': tokenId,
        }
      })
        .then((res) => {
          console.log(res);
          if (res.data.status === true) {
            setStatus(true);
            history.push("/app/success");
            // res.data.adminUser && res.data.adminUser.userType && setUserType(res.data.adminUser.userType)
            /* setApp(appName)
                  setUserId(email) */
          } else if (res.data.errorMessage && res.data.errorMessage !== "") {
            setErrorMsg(res.data.errorMessage);
          } else {
            // alert(res.data.errorMessage)
            // setLoginAlert('System Error, Please Try Again')
          }
        })
        .catch((error) => {
          if (error&&(error.response && error.response.status === 703) ||(error.response && error.response.status === 704) ||(error.response && error.response.status === 500) ||(error.response && error.response.status === 504) || (error.response && error.response.status === 502)) {
            //refreshToken();
          }
        });
    } else {
      setErrorMsg("New password and confirm new password does not match");
    }
  };
  const login = () => {
    setUserId(null);
    history.push("/login");
  };
  const navigationHome = () => {
    history.push("/homepage")
  }
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);
  function passwordReducer(state, action) {
    const { value } = action;
    const { fname, lname } = userDetails;
    let str = [],regcheckValue = value;
    const minMaxLength = /^[\S]{8,32}$/g,upper = /[A-Z]/g,lower = /[a-z]/g,number = /[0-9]/g,special = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/g;
    if (value) {
      regcheckValue = regcheckValue
        .replace(upper, "")
        .replace(lower, "")
        .replace(number, "")
        .replace(special, "")
        .replace(/\s/g, "");
      if (regcheckValue) {
        str = ["No Invalid Characters"];
        return {
          Error: true,
          Label: "Invalid Password",
          helperText: "Invalid Characters are not allowed",
          value,
        };
      } else {
        for (let name of passwordExceptionWords) {
          if (new RegExp(name, "i").test(value)) {
            str.push("No special words like 'password','qwerty'");
            break;
          }
        }
        if (/\s/g.test(value) && value) {
          str.push("No whiteSpace Characters");
        } else if (
          email &&
          email.split("@")[0] &&
          new RegExp(email.split("@")[0], "i").test(value)
        ) {
          str.push("Password contains username");
        } else if (str.length === 0) {
          if (/.com/g.test(value) && value) {
            str.push("Not include email address");
          }

          if (!upper.test(value) && value) {
            str.push("Atleast one Upper Case Letter");
          }
          if (!lower.test(value) && value) {
            str.push("Atleast one Lower Case Letter");
          }
          if (!number.test(value) && value) {
            str.push("Atleast one number");
          }
          if (!special.test(value) && value) {
            str.push("Atleast one Special Character");
          }
          if (
            (new RegExp(fname, "i").test(value) ||
              new RegExp(lname, "i").test(value))
          ) {
            str.push("Not include firstName and LastName");
          }
          /* do {
            for (let num = 0; num <= 23; num++) {
              if (new RegExp(alphabets.substr(num, 3)).test(value)) {
                str.push("No sequence characters like abc...,123... etc.");
                break;
              }
            }
            if (
              !str.includes("No sequence characters like abc...,123... etc.")
            ) {
              for (let num = 1; num <= 7; num++) {
                if (new RegExp(`${num}${num + 1}${num + 2}`).test(value)) {
                  str.push("No sequence characters like abc...,123... etc.");
                  break;
                }
              }
            }
          } while (false); */
        }

        if (str.length === 0 && value) {
          if (!minMaxLength.test(value)) {
            str.push("Password Should be of Length 8-32");
          }
        }
        if (str.length !== 0) {
          return {
            Error: true,
            Label: "Invalid Password",
            helperText: str,
            value,
          };
        } else if (str.length === 0) {
          return {
            Error: false,
            Label: "New Password",
            helperText: "",
            value,
          };
        }
      }
    } else {
      return {
        ...state,
        value,
        Error: false,
        Label: "New Password",
        helperText: "",
      };
    }
  }

  //console.log((true&&email&&oldPassword&&passField.value&&confirmPassword&&passField.value===confirmPassword),"Samsung")

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          margin: "auto",
          alignItems: "center",
          minHeight: "calc(100vh - 60px)",
          paddingTop: 64,
          flexDirection:'column'
        }}
      >
        <AppBar><Toolbar className={passwordStyles.toolBar}>
      <div>
                <img
                  style={{
                    float: "left",
                    alignSelf: "center",
                  }}
                  src={oc_logo}
                  alt={"logo"}
                  importance="low"
                />
                <div
                  style={{
                    float: "left",
                    marginLeft: "5px",
                    fontSize: "2.1rem",
                    color:"initial"
                  }}
                >
                  Omnicure
                </div></div>
                <div>
                <HomeIcon className={passwordStyles.iconStyle} color="primary" onClick={navigationHome}/>
        <SettingsIcon
                aria-controls="simple-menu"
                aria-haspopup="true"
                className={passwordStyles.iconStyle}
                color="primary"                
                onClick={handleMenuClick}
              />
                            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                style={{ margin: "55px 0 0 0" }}
              >
                <MenuItem className={passwordStyles.menuList} disabled>
                  <a onClick={changePasswordScreen}>Change Password</a>
                </MenuItem>
              </Menu>
                      <ExitToAppIcon
              className={passwordStyles.iconStyle}
              color="primary"
              onClick={signOut}
            />
            </div>
        </Toolbar>
        </AppBar>
        {status === false && (
          <div
            style={{
              margin: "auto",
              backgroundColor: "white",
              /* justifyContent: "space-evenly", */
              display: "flex",
              
              flexDirection: "column",
              
              ...(mobileScreen
                ? {
                    justifyContent: "space-evenly",
                    width: "95%",
                    height: "75vh",
                    padding:'0 15px'
                  }
                : {
                    padding: "55px 60px 0 60px",
                    minWidth: "440px",
                    minHeight: "445px",
                  }),
            }}
          >
            <h6
              style={{
                marginBottom: "20px",
                color: "grey",
                fontSize: "18px",
              }}
            >
              Change Password
            </h6>
            {/* <TextField
              style={{ marginBottom: "20px" }}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              label="Email"
              variant="outlined"
            /> */}
            <TextField
              style={{ marginBottom: "20px" }}
              value={oldPassword}
              onChange={(event) => setOldPassword(event.target.value)}
              label="Old Password"
              variant="outlined"
              type={showOldPassword ? "text" : "password"}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickOldShowPassword}
                      onMouseDown={handleMouseOldDownPassword}
                    >
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              style={{ marginBottom: "20px" }}
              error={passField.Error}
              /* className={
                passField.value && !passField.Error && passwordStyles.root
              } */
              FormHelperTextProps={{ classes: passwordStyles }}
              id="outlined-error-helper-text"
              type={showPassword ? "text" : "password"}
              label={passField.Label}
              value={passField.value}
              onChange={(e) => {
                setPassValue({ value: e.target.value });
                setErrorMsg("");
              }}
              variant="outlined"
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      style={{ color: "#9d9d9d!important" }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    <LightTooltip title="Atleast one uppercase and lowercase Letter, one number, one special character.">
                      <InfoOutlinedIcon
                        style={{ color: "#9b51e0 !important" }}
                        onClick={(e)=>{
                          mobileScreen&&setPopup(e.currentTarget.parentNode.previousSibling)
                        }}
                      ></InfoOutlinedIcon>
                    </LightTooltip>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              style={{ marginBottom: "20px" }}
              value={confirmPassword}
              /* className={
                passField.value &&
                confirmPassword &&
                clsx({
                  [passwordStyles.root]: passField.value === confirmPassword,
                  [passwordStyles.partialCorrect]:
                    passField.value !== confirmPassword &&
                    passField.value.indexOf(confirmPassword) === 0,
                  [passwordStyles.wrong]:
                    passField.value !== confirmPassword &&
                    passField.value.indexOf(confirmPassword) === -1,
                })
              } */
              onChange={(event) => setConfirmPassword(event.target.value)}
              label="Confirm New Password"
              type={showConfirmPassword ? "text" : "password"}
              variant="outlined"
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickConfirmShowPassword}
                      onMouseDown={handleMouseConfirmDownPassword}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    <LightTooltip title="Atleast one uppercase and lowercase Letter, one number, one special character.">
                      <InfoOutlinedIcon onClick={(e)=>{
                        mobileScreen&&setPopup(e.currentTarget.parentNode.previousSibling)
                      }}></InfoOutlinedIcon>
                    </LightTooltip>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              style={{ marginBottom: "0px" }}
              color="primary"
              variant="contained"
              onClick={submit}
              className={
                !(
                  oldPassword &&
                  passField.value &&
                  !passField.Error &&
                  confirmPassword
                ) && passwordStyles.btndisable
              }
            >
              Submit
            </Button>
            <Popover open={Boolean(popup)} 
            anchorEl={popup}
            onClose={()=>{
              setPopup(null)
            }}
            anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} >"Atleast one uppercase and lowercase Letter, one number, one special character."</Popover>
            <div>
              {passField.helperText.length > 0 && (
                <List className={passwordStyles.ullist}>
                  {passField.helperText.map((validationMsg) => (
                    <ListItem>
                      <ListItemText primary={validationMsg} />
                    </ListItem>
                  ))}
                </List>
              )}

              {errorMsg !== "" && (
                <span style={{ fontSize: "12px", color: "red" }}>
                  {errorMsg}
                </span>
              )}
            </div>
          </div>
        )}
        {status === true && (
          <div
            style={{
              padding: "50px",
              margin: "auto",
              backgroundColor: "white",
              justifyContent: "space-evenly",
              display: "flex",
              minWidth: "400px",
              flexDirection: "column",
              fontSize: "16px",
            }}
          >
            <span>
              New Password Successfully Updated{" "}
              <a
                style={{ cursor: "pointer", color: "#9b51e0" }}
                onClick={() => {
                  login();
                }}
              >
                Login
              </a>
            </span>
          </div>
        )}
      </div>
      {expiredMsg && <div><ExpiredMessage expiredMsg={expiredMsg} setexpiredLogin={setexpiredLogin}/></div>}
    </div>
  );
}

export default ChangePasswordScreen;
