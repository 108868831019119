import { render } from "@testing-library/react";
import { makeStyles } from "@material-ui/core/styles";
import React, {useEffect, useState} from "react"
import {useHistory} from "react-router-dom";

const useStyles = makeStyles({
    mainbox :{
        margin: "30px auto",
        height: "auto",
        width: "auto",
        position: "relative",
        padding: "120px",
        backgroundColor: "#ffffff"
    },
      
    err :{
        color: "#9B51E0",
        fontSize: "3rem",
        textAlign: "center",
        padding: "15px 0"
    },
      
    msg :{
        textAlign: "center",
        fontSize: "1rem",
        padding: "30px 0"
    },
      
    a :{
        textDecoration: "none",
        color: "#9B51E0",
        cursor: "pointer"
    },
});

const ErrorScreen = () =>{
    const classes = useStyles();
    const history = useHistory(); 
    const goback =() =>{
        history.push("/hompage")
    }
    return(
        <div className={classes.mainbox}>
            <div className={classes.err}  color={"primary"}>Access Denied</div>
            <div className={classes.err}  color={"primary"}>404</div>
            <div className={classes.msg}><p>You should not access this way Let's go <a className={classes.a} onClick={goback}>home</a> and try from there.</p></div>
        </div>
    );
};
export default ErrorScreen;