import React,{useState,useEffect} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useRecoilState, useRecoilValue } from 'recoil';
import {Firebase,FirebaseApp,ShowNotification,AlertListNotification} from 'App';
import "firebase/database";
import CloseIcon from '@material-ui/icons/Close';  
import IconButton from '@material-ui/core/IconButton';
import {isEmpty} from 'ramda';

const NotificationManagement = () => {
    const [firebase] = useRecoilState(Firebase);
    const [firebaseApp] = useRecoilState(FirebaseApp);
    const [notificationList] = useRecoilState(AlertListNotification)
    const [alertsDictionary,setAlertsDictionary] = useRecoilState(ShowNotification);
    const [notificationOpen,setNotificationOpen] = useState(false)
    const [notificationMessage,setNotificationMessage] = useState('')
    const [initialRender,setInitialRender] = useState(true);

    console.log(notificationList,alertsDictionary,"InitialRenderCheck")

    useEffect(()=>{
      console.log("SetInitialRender1")
      console.log(alertsDictionary,initialRender,"InitialNotificationCheck")
      if(!isEmpty(alertsDictionary)){
        setNotificationOpen(true)
        setNotificationMessage(alertsDictionary?.message)
      }
      return ()=>{
        setNotificationOpen(false)
      }
    },[alertsDictionary])
    // useEffect(()=>{
    //   try{
    //   const dataBase = firebaseApp.database('https://dev-omnicure-f9914.firebaseio.com/')
    //   dataBase.ref('alertsList').on('value',(snapshot)=>{
    //     const {AlertsCheck,AlertsMessage} = snapshot.val();
    //     if(AlertsCheck){
    //         setNotificationOpen(true);
    //         setNotificationMessage(AlertsMessage)
    //     }
    //     console.log(snapshot.val(),snapshot,"SnapShot")
    // })}
    // catch(error){
    
    // }
    // },[])
    return (
        <div>
      <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
        open={notificationOpen}
        autoHideDuration={10000}	
        onClose={()=>{
            setNotificationOpen(false)
            setAlertsDictionary({})
            // const dataBase = firebaseApp.database('https://dev-omnicure-f9914.firebaseio.com/')
            // dataBase.ref().set({AlertsMessage:'',AlertsCheck:false})
        }}
        message={notificationMessage}
        key={"Notification"}
        action={<IconButton size="small" aria-label="close" color="inherit" onClick={()=>{
            setNotificationOpen(false)
            setAlertsDictionary({})
            // const updatedNotificationList = notificationList.map(listValues=>{
            //   if(listValues.id===alertsDictionary.id){
            //     return {...alertsDictionary,read:true}
            //   }
            //   return listValues
            // })
            // const dataBase = firebaseApp.database('https://dev-omnicure-f9914.firebaseio.com/')
            // dataBase.ref('alertsList').set(updatedNotificationList)
        }}>
        <CloseIcon fontSize="small" />
      </IconButton>}
      />
    </div>
    )
}

export default NotificationManagement;