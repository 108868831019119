import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import {sort} from "ramda";
import { TextField, Button,Link  } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles,withStyles  } from "@material-ui/core/styles";
import memoize from "memoize-one";
import CustomLoader from "../../common/CustomLoader";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from 'moment';
import clsx from 'clsx';
import API from "../../../api";
import {useHistory} from "react-router-dom";
import {usersID,userIDBoolean} from "App";
import {useRecoilValue} from 'recoil';
import axios from 'axios';
import createAuthRefreshInterceptor from "axios-auth-refresh";
//import ExpiredMessage from "../../common/ExpiredMessage";
const subHeader = React.createRef();

const TextContainerStyles = {
    messageElement:{
      display:'box',
      boxOrient: 'vertical',  
      overflow: 'hidden',
    },
    expandLess:{
        lineClamp: 2,
    },
    expandMore:{
        lineClamp:'unset'
    }
}

const TextContainer = (props) => {
    const [expand,setExpansion] = useState(false)
    const [toggle,setToggle] = useState(false)
    const msgElement = useRef(null);
    const {classes} = props;
    useEffect(()=>{
      msgElement.current?.scrollHeight>msgElement.current?.clientHeight&&setToggle(true);
    },[])
return (<div><div className={clsx({[classes.messageElement]:true,[classes.expandMore]:expand,[classes.expandLess]:!expand})} ref={msgElement}>{props.message}</div>
{toggle&&<Link
    component="button"
    variant="body2"
    onClick={() => {
      setExpansion(!expand)
    }}
  >
    {expand?'Show Less':'Show More'}
  </Link>}</div>)
}

const StyledTextContainer = withStyles(TextContainerStyles)(TextContainer)

const Data = [{
    title:'Title One',
    message:'Wikipedia has been criticized for its uneven accuracy and for exhibiting systemic bias, particularly gender bias, with the majority of editors being male.[8] In 2006, Time magazine stated that the open-door policy of allowing anyone to edit had made Wikipedia the "biggest and perhaps the best encyclopedia in the world", and a testament to the vision of Jimmy Wales.[9] The project\'s reputation improved further in the 2010s, as it received praise for its unique structure, culture, and absence of commercial bias.[3][8] In 2018, Facebook and YouTube announced that they would help users detect fake news by suggesting links to related Wikipedia articles.[10]',
    sentTime:'1613737930197'
},
{
    title:'Title Two',
    message:'Wikipedia was launched on January 15, 2001, by Jimmy Wales and Larry Sanger; its name was coined as a portmanteau of "wiki" and "encyclopedia". Initially available only in English, versions in other languages were quickly developed. The English Wikipedia, with 6.3 million articles as of March 2021, is the largest of the 319 language editions. Combined, Wikipedia\'s editions comprise more than 55 million articles, and attract more than 17 million edits and more than 1.7 billion unique visitors per month.',
    sentTime:'1613649893297'
}]

const columns = [{
    name:'Title',
    selector: 'title',
    wrap:true,    
    grow:0,
    width:'250px'
},{
    name:'Message',
    selector: 'alertMsg',
    wrap:true,
    minWidth:'550px',
    grow:1,
    cell:({alertMsg})=><StyledTextContainer message={alertMsg} />
},{
    name:'Date and Time',
    selector: 'createdTime',
    wrap:true,
    grow:0,
    format:({createdTime})=>moment(Number(createdTime)).format('DD MMM YYYY hh:mm a'),
    width:'200px'
}]

const useFilterComponentStyles = makeStyles({
  inputWidth: {
    width: "80%",
  },
});

const FilterComponent = ({ filterText, onFilter, onClear }) => {
  const screenMediaQuery = useMediaQuery(`(max-width:635px)`);
  const classes = useFilterComponentStyles();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginBottom: screenMediaQuery ? 15 : 0,
      }}
    >
      <TextField
        id="search"
        type="text"
        placeholder="Search by Title"
        className={classes.inputWidth}
        value={filterText}
        onChange={onFilter}
      />
      <ClearIcon onClick={onClear} />
    </div>
  );
};

const SubHeaderComponentMemo = ({
    handleClear,
    filterText,
    buttonClick,
    filterTextset,
  }) => {
    const screenMediaQuery = useMediaQuery(`(max-width:635px)`);
    return (
      <div
        style={{ display: "flex", flexDirection: "column", position: "relative" }}
        ref={subHeader}
      >
        <FilterComponent
          onFilter={filterTextset}
          onClear={handleClear}
          filterText={filterText}
        />
      </div>
    );
  };

const AlertList = ({loading, tokenId, refreshId, setTokenId, setUserId, setExpiredMsg}) => {
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [alertData,setAlertData]=useState([]);
    const [filteredData,setFilteredData] = useState([]);
    const [progressPending,setProgressPending] = useState(true);
    //const [expiredMsg, setExpiredMsg] = useState(true);
    //const [expiredLogin, setexpiredLogin] = useState(false);
    const history = useHistory();
    const tableRef = useRef(null);

  const userId = useRecoilValue(usersID)
  const userIDPermission = useRecoilValue(userIDBoolean)

    useEffect(()=>{
      if(window.innerWidth>636){
      //tableRef.current.firstChild.style.width = `${tableRef.current.querySelector('[role="table"]').clientWidth}px`
      var ro = new ResizeObserver(entries => {
        for (let entry of entries) {
          const cr = entry.contentRect;
          console.log("Check the CR",cr)
          tableRef.current.firstChild.style.minWidth=`${cr.width}px`;
        }
      });
      
      ro.observe(tableRef.current.querySelector('[role="table"]'))
      return ()=>{
        ro.unobserve(tableRef.current.querySelector('[role="table"]'))
      }}
    },[])
    // API.interceptors.response.use(
    //   function(response) {
    //     // If the request succeeds, we don't have to do anything and just return the response
    //     return response
    //   },
    //   function(error) {
    //     console.log(error.response,"Check The Error")
    //     const errorResponse = error.response
    //     if([401,500,502,504,703,704].includes(errorResponse.status)){
    //       return new Promise(resolve=>{
    //         API.post("/userEndpoints/v1/renewIdToken",
    //            {
    //                "token": refreshId
    //            }).then(res=>{
    //             setTokenId(res.data.idToken)
    //             if(!res.data.idToken){
    //               setExpiredMsg(true)
    //               Promise.reject()
    //             }
    //             errorResponse.config.headers.Authorization = res.data.idToken
    //             resolve(axios(errorResponse.config))
    //            }).catch(error=>{
    //              Promise.reject(error)
    //            })
    //       })
    //     }
    //     // if (isTokenExpiredError(errorResponse)) {
    //     //   return resetTokenAndReattemptRequest(error)
    //     // }
    //     // If the error is due to other reasons, we just throw it back to axios
    //     return Promise.reject(error)
    //   }
    // )
    //useEffect(()=>{
    //   API.get(`/adminUserEndpoints/v1/getSystemAlerts`).then(({data:{systemAlertList}})=>{
    //     const DateTimeSorted = sort(function(a,b){
    //       return new Date(Number(a.createdTime)) - new Date(Number(b.createdTime))
    //     },systemAlertList)
    //     setAlertData(DateTimeSorted);
    //     const filteredDataOutput = DateTimeSorted.filter(({title})=>title.startsWith(filterText))
    //     setFilteredData(filteredDataOutput)
    //     setProgressPending(false)
    //   })
    // },[])
    // useEffect(()=>{
    //   const refreshAuthLogic = failedRequest =>
    //   API.post("/userEndpoints/v1/renewIdToken",{   
    //     "token": refreshId
    //   })
    //   .then((res)=>{
    //     var tokenId = res.data.idToken
    //     setTokenId(res.data.idToken)
    //     if(!res.data.idToken){
    //       setExpiredMsg(true)
    //       //console.log('expiredLogin', expiredLogin)
    //       //if(expiredLogin){          
    //         //setUserId(null)
    //         //history.push("/")
    //         return Promise.reject();
    //       //}
    //     }
    //     failedRequest.response.config.headers["Authorization"] = tokenId;
    //   return Promise.resolve();
    //   })
    //   .catch((error) =>{
    //     console.log("refresh fail");
    //     return Promise.reject(error);
    //   })
    //   createAuthRefreshInterceptor(API, refreshAuthLogic,{statusCodes:[401,500,502,504,703,704]});
    // })
    const refreshToken = () => {
      API.post("/userEndpoints/v1/renewIdToken",{
        "token": refreshId
      })
      .then((res)=>{
        setTokenId(res.data.idToken)
        if(!res.data.idToken){
          setExpiredMsg(true)
        }
      })
      .catch((error) =>{})
    }

    useEffect(()=>{
    if(!loading){
      API.get(`/adminUserEndpoints/v1/getSystemAlerts`,{
        headers: {
          'Authorization': tokenId,
        },...userIDPermission&&{
          params:{userId}
        }
      }).then(({data:{systemAlertList=[]}})=>{
        const DateTimeSorted = sort(function(a,b){
          return new Date(Number(b.createdTime)) - new Date(Number(a.createdTime))
        },systemAlertList)
        setAlertData(DateTimeSorted);
        const filteredDataOutput = DateTimeSorted.filter(({title})=>title.startsWith(filterText))
        setFilteredData(filteredDataOutput)
        setProgressPending(false)
      }).catch((error)=>{
        if (error&&(error.response && error.response.status === 703) ||(error.response && error.response.status === 704) ||(error.response && error.response.status === 500) ||(error.response && error.response.status === 504) || (error.response && error.response.status === 502)) {
          //refreshToken();
        }
      })
    }  
    },[loading])
    
    useEffect(()=>{
        const filteredDataOutput = alertData.filter(({title})=>title.startsWith(filterText))
        setFilteredData(filteredDataOutput)
    },[filterText])
    const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText("");
        }
      };
      const filterTextset = (e) => {
        setFilterText(e.target.value);
      };

      const screenMediaQuery = useMediaQuery("(max-width:635px)");
    return(
        <div style={{ width: "100%" }}>
          {/*  <div><ExpiredMessage expiredMsg={expiredMsg} setexpiredLogin={setexpiredLogin}/></div> */}
      {screenMediaQuery && (
        <SubHeaderComponentMemo
          {...{ handleClear, filterTextset, filterText }}
        />
      )}
      <div style={{ backgroundColor: "#FFFFFF", width: "100%" }} ref={tableRef}>
        <DataTable
          style={{
            width: "100%",
            ...(!screenMediaQuery && { minWidth: "800px" }),
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
          }}
          title="Alert List"
          columns={columns}
          customStyles={{headCells:{style:{fontSize:14}},rows:{style:{'&:nth-of-type(2n+1)':{backgroundColor:'rgba(0, 0, 0, 0.08)'}}}}}
          data={filteredData}
          progressPending={progressPending}
          progressComponent={<CustomLoader />}
          noDataComponent={
            <p
              style={{
                fontSize: "1.5rem",
                margin: "20px",
              }}
            >
              No records found.
            </p>
          }
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader={!screenMediaQuery}
          subHeaderComponent={
            !screenMediaQuery && (
              <SubHeaderComponentMemo
                {...{ handleClear, filterTextset,  filterText }}
              />
            )
          }
          persistTableHead
        />
      </div>
    </div>
    )
}

export default AlertList;

