import React, { useState, useEffect } from "react";
import { TextField, Button } from "@material-ui/core";
import "./AddHospitalScreen.css";
import API from "../../../api";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AlertList from "../Lists/AlertList";
import {useHistory} from "react-router-dom";
import ExpiredMessage from "../../common/ExpiredMessage";
import {usersID,userIDBoolean} from "App";
import {useRecoilValue} from 'recoil';
import createAuthRefreshInterceptor from "axios-auth-refresh";
import axios from 'axios';

const SystemAlertScreen = (props) => {
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [systemAlert, setSystemAlert] = useState("");
  const [open, setOpen] = useState(true);
  const [titleError, setTitleError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const screenMediaQuery = useMediaQuery(`(max-width:635px)`);
  const [alertList, showAlertList] = useState(false);
  const [expiredMsg, setExpiredMsg] = useState(false);
  const [expiredLogin, setexpiredLogin] = useState(false);
  const history = useHistory();
  const userId = useRecoilValue(usersID)
  const userIDPermission = useRecoilValue(userIDBoolean)
  const retryApiGet = (url,header)=>{
    return API.get(url,header).catch(function(error){console.log(error,error.response,"Retry Fetch");if([401,500,502,504,703,704].includes(error?.response?.status)){
      return new Promise(resolve=>{
        API.post("/userEndpoints/v1/renewIdToken",{
          "token": props.refreshId
        })
        .then((res)=>{
          props.setTokenId(res.data.idToken)
          if(!res.data.idToken){
            setExpiredMsg(true)
            if(expiredLogin){
              props.setUserId(null)
              history.push("/")
            }
            throw error
          }else{
            resolve(API.get(url,{headers:{
              Authorization:res.data.idToken
            }}))
          }
        })
        .catch((error) =>{Promise.reject(error)})
      })
    }})
  }
  
  const retryApiPost = (url,data,header)=>{
    return API.post(url,data,header).catch(function(error){console.log(error,error.response,"Retry Fetch");if([401,500,502,504,703,704].includes(error?.response?.status)){
      return new Promise(resolve=>{
        API.post("/userEndpoints/v1/renewIdToken",{
          "token": props.refreshId
        })
        .then((res)=>{
          props.setTokenId(res.data.idToken)
          if(!res.data.idToken){
            setExpiredMsg(true)
            if(expiredLogin){
              props.setUserId(null)
              history.push("/")
            }
            throw error
          }else{
            resolve(API.post(url,data,{headers:{
              Authorization:res.data.idToken
            }}))
          }
        })
        .catch((error) =>{Promise.reject(error)})
      })
    }})
  }

  useEffect(() => () => {
    titleError && setTitleError(false);
    messageError && setMessageError(false);
  });
  const refreshToken = () => {
    API.post("/userEndpoints/v1/renewIdToken",{
      "token": props.refreshId
    })
    .then((res)=>{
      props.setTokenId(res.data.idToken)
      if(!res.data.idToken){
        setExpiredMsg(true)
        if(expiredLogin){
          props.setUserId(null)
          history.push("/")
        }
      }
    })
    .catch((error) =>{})
  }
  // useEffect(()=>{
  //   const refreshAuthLogic = failedRequest =>
  //   API.post("/userEndpoints/v1/renewIdToken",{   
  //     "token": props.refreshId
  //   })
  //   .then((res)=>{
  //     props.setTokenId(res.data.idToken)
  //     if(!res.data.idToken){
  //       setExpiredMsg(true)
  //       if(expiredLogin){
  //         props.setUserId(null)
  //         history.push("/")
  //         return Promise.reject();
  //       }
  //       return Promise.reject();
  //     }
  //     failedRequest.response.config.headers["Authorization"] = res.data.idToken;
  //   return Promise.resolve();
  //   })
  //   .catch((error) =>{
  //     console.log("refresh fail");
  //     return Promise.reject(error);
  //   })
  //   createAuthRefreshInterceptor(API, refreshAuthLogic,{statusCodes:[401,500,502,504,703,704]});
  // },[])
  useEffect(() =>{
    if(expiredLogin){
      props.setUserId(null)
      history.push("/")
    }
  },[expiredLogin])
  return (
    <div>
      {systemAlert !== "" && (
        <div
          style={{ fontSize: "18px", color: "#fb1b1b", padding: "0 0 10px" }}
        >
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                    setMessage("");
                    setTitle("");
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {systemAlert}
            </Alert>
          </Collapse>
        </div>
      )}
      <div
        style={{
          margin: "auto",
          padding: `15px ${screenMediaQuery ? "15px" : "35px"} 25px ${
            screenMediaQuery ? "15px" : "35px"
          }`,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          /*  maxWidth: '700px', */
        }}
      >
        <h5> System Alert</h5>

        <div
          style={{
            display: "flex",
            backgroundColor: "white",
            ...(!screenMediaQuery && { minWidth: "400px" }),

            opacity: 2,
            flexDirection: "column",
          }}
        >
          <TextField
            style={{ margin: "10px" }}
            variant="outlined"
            value={title}
            error={titleError}
            helperText={titleError && "Please Enter Title"}
            onChange={(event) => setTitle(event.target.value)}
            label="Title"
          />
          <TextField
            style={{ margin: "10px" }}
            error={messageError}
            helperText={messageError && "Please Enter Message"}
            multiline
            rows={4}
            variant="outlined"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            label="Message"
          />
        </div>
        <div
          style={{ display: "flex", columnGap: 10, justifyContent: "center" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              //setSystemAlert("hi")
              if (/^[\s|\n]*$/.test(title) && /^[\s|\n]*$/.test(message)) {
                //open||setOpen(true)
                //setSystemAlert("Please enter title and message")
                //setMessage('');
                //setTitle('');
                setTitleError(true);
                setMessageError(true);
              } else if (/^[\s|\n]*$/.test(title)) {
                //open||setOpen(true)
                //setSystemAlert("Please enter title")
                //setTitle('');
                setTitleError(true);
              } else if (/^[\s|\n]*$/.test(message)) {
                //open||setOpen(true)
                //setSystemAlert("Please enter message")
                //setMessage('');
                setMessageError(true);
              } else {
                setLoading(true);
                retryApiPost("/adminUserEndpoints/v1/postAlert", {
                  alertTitle: title,
                  alertMsg: message,
                  // adminId: ''
                },
                {
                  headers: {
                    'Authorization': props.tokenId,
                  },
                  ...userIDPermission&&{
                    params:{userId}
                  }
                })
                  .then((res) => {
                    setLoading(false);
                    setSystemAlert('')
                    if (res.data.status) {
                      setMessage("");
                      setTitle("");
                    } else {
                      // alert(res)
                      // setSystemAlert(res)
                    }
                  })
                  .catch((error) => {
                    setLoading(false);
                    if (error&&(error.response && error.response.status === 703) ||(error.response && error.response.status === 704)) {
                      //refreshToken();
                    } else if(error&&error.response && error.response.status === 504){
                      //refreshToken();
                      setSystemAlert('Server did not response please try again...')
                    } else if(error&&error.response && error.response.status === 500){
                      //refreshToken();
                      setSystemAlert('')
                    } else if(error&&error.response && error.response.status === 502){
                      //refreshToken();
                    }
                    // alert(JSON.stringify(reason))
                    // setSystemAlert(JSON.stringify(reason))
                  });
              }
            }}
            disableElevation
            disabled={loading}
          >
            Send Alert
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              showAlertList(true);
            }}
          >
            View Alerts
          </Button>
        </div>
      </div>
      {expiredMsg && <div><ExpiredMessage expiredMsg={expiredMsg} setexpiredLogin={setexpiredLogin}/></div>}
      {alertList && <div style={{width:'100%',overflow:'auto'}}><AlertList loading={loading} tokenId={props.tokenId} refreshId={props.refreshId} setRefreshId={props.setRefreshId} setTokenId={props.setTokenId} setUserId={props.setUserId}  setExpiredMsg={setExpiredMsg} /></div>}
    </div>
  );
};
export default SystemAlertScreen;
