import React, { useEffect,useLayoutEffect,useState, useMemo, Suspense } from "react";
import "./App.css";
import {
  ADMIN_APP,
  DASHBOARD_APP,
  PATIENT_SCREEN,
  LOGIN_SCREEN,
  DASHBOARD_SCREEN,
  HOSPITAL_SCREEN,
  PagesList,
  allRoutes
} from "./AppConstants";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  withRouter,
  useLocation
} from "react-router-dom";
import LoginScreen from "./components/admin/screens/LoginScreen";
import API from "./api";
import SystemAlertScreen from "./components/admin/screens/SystemAlertScreen";
import CustomAlert from "./components/common/Alert";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DashboardIcon from "@material-ui/icons/Dashboard";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import PolicyIcon from '@material-ui/icons/Policy';
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import Typography from "@material-ui/core/Typography";
import ChatIcon from '@material-ui/icons/Chat';
import NavigationComponent from "./components/common/NavigationComponent";
import ChangePasswordScreen from "./components/common/ChangePassword";
import SuccessScreen from "./components/common/successScreen";
import Link from '@material-ui/core/Link';
import ForgotPasswordScreen from "./components/admin/screens/ForgotPassword";
import  firebase from "firebase/app";
import NotificationComponent from "components/common/NotificationManagement";
import "firebase/auth";
import ErrorScreen from "./components/common/ErrorComponent";
import {isEmpty,reject,findIndex,remove,pluck} from "ramda"
import {atom,useRecoilState,selectorFamily,useRecoilValue,selector,useSetRecoilState} from 'recoil';

console.log("Check Devops")

// const PermissionCollection = {
//   enterpriseAdmin:['providerManagement','patientManagement','eConsultRecord'],
//   supportadminAccess:['patientManagement','eConsultRecord']
// }

let permissionValue = localStorage.getItem('permission')
if(permissionValue){permissionValue=JSON.parse(permissionValue)}

const usersUid = localStorage.getItem('usersuid')

export const permission = atom({
  key:'permission',
  default:[]
})

export const PermissionCollection = atom({
  key:"permissionCollection",
  default:{
  }
  // enterpriseAdmin:['providerManagement','patientManagement','eConsultRecord'],
  // supportadminAccess:['patientManagement','eConsultRecord']
})

export const usersID = atom({
  key:'usersUID',
  default:usersUid?usersUid:''
})

export const userIDBoolean = selector({
  key:'userIDBoolean',
  get:({get})=>{
    const permissionArray = get(permission)
    if(permissionArray.includes('superAdmin')){
      return false
    }
    return permissionArray.includes('enterpriseAdmin') || permissionArray.includes('supportadminAccess')
  }
})

export const UILevelPermission = selectorFamily({
  key:'uilevelpermission',
  get:(UIComponent)=>({get})=>{
     const permissionArray = get(permission);
     const permissionList = get(PermissionCollection)
     if(permissionArray.includes('superAdmin')){
       return true
     }
     if(UIComponent==='dashboardAccess'&&permissionArray.some((permissionRole)=>['dashboardSuperAdmin','dashboardUserAdmin'].includes(permissionRole))){
       return true
     }
     if(UIComponent==='adminAccess'&&permissionArray.some((permissionRole)=>['enterpriseAdmin','supportadminAccess'].includes(permissionRole))){
       return true
     }
     if(UIComponent==='webPortalAccess'&&permissionArray.includes('webPortalUser')){
       return true
     }
     return permissionArray.some((permissionRole)=>permissionList[permissionRole]?.includes(UIComponent))
  }
})

/* import  firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics"
import {isEmpty,reject,findIndex,remove} from "ramda"
/*import "firebase/firestore"
import axios from "axios"
import "firebase/functions"; */

/* const firebaseConfigs = {
  apiKey: "AIzaSyB0ciCKAxKu37vGUbl6gBNSb0fC5Ff44a0",
  authDomain: "dev-omnicure.firebaseapp.com",
  databaseURL: "https://dev-omnicure.firebaseio.com",
  projectId: "dev-omnicure",
  storageBucket: "dev-omnicure.appspot.com",
  messagingSenderId: "462063129427",
  appId: "1:462063129427:web:d1fb129ea379d30441c7a6",
  measurementId: "G-7CMWQ1D0HX"
};
  // Initialize Firebase
firebase.initializeApp(firebaseConfigs);
firebase.analytics(); */

// var firebaseConfig = {
//   apiKey: "AIzaSyDLmPi6ZB8LVzsO0GMSsAqQvEjdKEVMxIU",
//   authDomain: "qa-omnicure.firebaseapp.com",
//   databaseURL: "https://qa-omnicure-default-rtdb.firebaseio.com",
//   projectId: "qa-omnicure",
//   storageBucket: "qa-omnicure.appspot.com",
//   messagingSenderId: "699680151510",
//   appId: "1:699680151510:web:c072a699783faea863b677",
//   measurementId: "G-H4WCYHYB47"
// };

const {REACT_APP_apiKey,REACT_APP_authDomain,REACT_APP_databaseURL,REACT_APP_projectId,REACT_APP_storageBucket,REACT_APP_messagingSenderId,REACT_APP_appId,REACT_APP_measurementId} = process.env

var firebaseConfig = {
  apiKey: REACT_APP_apiKey,
  authDomain: REACT_APP_authDomain,
  databaseURL: REACT_APP_databaseURL,
  projectId: REACT_APP_projectId,
  storageBucket: REACT_APP_storageBucket,
  messagingSenderId: REACT_APP_messagingSenderId,
  appId: REACT_APP_appId,
  measurementId: REACT_APP_measurementId
};

// const firebaseConfig = {
//   apiKey: "AIzaSyB0ciCKAxKu37vGUbl6gBNSb0fC5Ff44a0",
//   authDomain: "dev-omnicure.firebaseapp.com",
//   databaseURL: "https://dev-omnicure.firebaseio.com",
//   projectId: "dev-omnicure",
//   storageBucket: "dev-omnicure.appspot.com",
//   messagingSenderId: "462063129427",
//   appId: "1:462063129427:web:d1fb129ea379d30441c7a6",
//   measurementId: "G-7CMWQ1D0HX"
// };
  // Initialize Firebase
const Firebase_APP =firebase.initializeApp(firebaseConfig);

firebase.analytics();

firebase.auth().onAuthStateChanged((user) => {
  console.log(user,"Firebase")
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    var uid = user.uid;
    console.log(user.id,"Check id inside firebase")
    // ...
  } else {
    // User is signed out
    // ...
  }
});

console.log("Check This Firebase",firebase.auth().currentUser)

export const Firebase = atom({
  key:'Firebase',
  default: firebase
})

export const FirebaseApp = atom({
  key:'FirebaseApp',
  default: Firebase_APP
})

export const AlertListNotification = atom({
  key:'NotificationAlertList',
  default: []
})

export const unReadNotificationList = atom({
  key:'unreadnotificationlist',
  default:[]
})

/*export const ShowNotification = selector({
  key:'ShowNotification',
  get:({get}) => {
    const unreadNotiList = get(unReadNotificationList);
    return unreadNotiList[unreadNotiList.length-1] ? unreadNotiList[unreadNotiList.length-1]
:{}  }
  // get: ({get}) => {
  //   const AlertList = get(AlertListNotification);
  //   if(isEmpty(AlertList)){
  //     return {}
  //   }else{
  //   const loggedInTime = Number(localStorage.getItem('loggedInTime'))
  //   console.log(loggedInTime,"loggedinTimeSelector ")
  //   const latestnotification = AlertList.reduce((acc,current)=>{
  //     if(acc?.alertsTime>current?.alertsTime){
  //       return acc
  //     }
  //     else if(acc?.alertsTime<current?.alertsTime){
  //       return current
  //     }
  //     else if(acc?.alertsTime===current?.alertsTime){
  //       return current
  //     }
  //   })
  //   console.log(latestnotification.alertsTime,loggedInTime,(loggedInTime<latestnotification.alertsTime),"latestNotification")
  //   if(loggedInTime<latestnotification.alertsTime){
  //     console.log((loggedInTime<latestnotification.alertsTime),"Check the time")
  //     return latestnotification
  //   }
  //   return {}
  // }},
  // set:({get,set},updatedValue)=>{
  //   const alertsList = get(AlertListNotification)
  //   const updatedAlertsList = alertsList.map(list=>{
  //     if(list.id===updatedValue.id){
  //       return updatedValue
  //     }
  //     return list
  //   })

  //   const dataBase = Firebase_APP.database('https://dev-omnicure-f9914.firebaseio.com/')
  //   dataBase.ref('alertsList').update(updatedAlertsList)
  //   console.log("Database Update Called")
  //   //firebase.database().ref().update(updates)
  //   //set(AlertListNotification,updatedAlertsList)
  // }
})*/

export const ShowNotification = atom({
  key:'ShowNotification',
  default:{}
})

export const unreadNotificationCount = selector({
  key:'unreadNotificationCount',
  get:({get})=>{
    return get(unReadNotificationList).length
  }
})

const AdminApp = React.lazy(() =>
  import(
    /* webpackChunkName: "AdminApp",webpackPreload: true */ "./AdminExports"
  )
);
const DashboardApp = React.lazy(() =>
  import(/* webpackChunkName: "DashboardApp"*/ "./DashBoardExports")
);
const TriageApp = React.lazy(() =>
  import(/* webpackChunkName: "TriageApp"*/ "./TriageExports")
);
const ResponsiveDrawer = React.lazy(() =>
  import(/* webpackChunkName: "DrawerToolbar"*/ "./components/common/SideBar")
);
export const PrivateRoute = ({
  children,
  isCurrentApp,
  userId,
  component,
  location,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      component={
        userId != null && isCurrentApp
          ? component
          : () => (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            )
      }
    />
  );
};

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      style={{ padding: "3px" }}
    >
      {"Copyright © "}
      <span color="inherit">{`${new Date().getFullYear()} Omnicure Inc.`}</span>
    </Typography>
  );
}
const userIdCache = localStorage.getItem("userId");
const appCache = localStorage.getItem("app");
const userTypeCache = localStorage.getItem("userType");
const userDetailsCache = localStorage.getItem("userDetails");
const globalToken = localStorage.getItem("tokenId");
const globalRefreshId = localStorage.getItem("refreshId");
/* const globalEmail = localStorage.getItem("userEmail");
const globalPassword = localStorage.getItem("userPassword"); */
const globalPermission = localStorage.getItem("userPermission");
const firebaseID = localStorage.getItem("firebaseID");

const RoutingComponent = ({location,history,userId,
  setUserId,
  app,
  setApp,
  setUserType,
  userType,
  setUserDetails,
  setUsersUid,
  setFirebaseId,
  setUserPermission,
  setRefreshId,
  setTokenId,loginRedirect}) => {
    console.log("I am rerendering Routing Component")
    //const history = useHistory()
    if(loginRedirect){
      return <div style={{ fontSize: "22px" }}>
      Please{" "}
      <a
        style={{
          textDecoration: "underline",
          color: "#9B51E0",
          cursor: "pointer",
        }}
        onClick={() => {
          setUserId(null);
          setUserDetails(null);
          setApp(null);
          setUsersUid(null)
          setFirebaseId(null)
          setUserPermission(null)
          setRefreshId(null)
          setTokenId(null)
          history.replace("/login");
        }}
      >
        Login
      </a>{" "}
      to access the application
    </div>
    }
    else if(app && PagesList[app].includes(location.pathname)){
      return <div
      style={{
        height: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="loader"></div>
    </div>
    }
    else if(app && allRoutes.includes(location.pathname)){
      console.log('Homepage_One')
      return <div style={{ fontSize: "22px" }}>
      Please{" "}
      <a
        style={{
          textDecoration: "underline",
          color: "#9B51E0",
          cursor: "pointer",
        }}
        onClick={() => {
          setUserId(null);
          setUserDetails(null);
          setApp(null);
          setUsersUid(null)
          setFirebaseId(null)
          setUserPermission(null)
          setRefreshId(null)
          setTokenId(null)
          history.push("/login");
        }}
      >
        Login
      </a>{" "}
      to access the application
    </div>
    }
    else if(userId){
      console.log('Homepage_two')
      return <Redirect to="/homepage" />
    }
    else if(!userId){
      return <Redirect to="/login" />
    }
    return null
  }

  //devops check

const App = ({ location }) => {
  const [userId, setUserId] = useState(userIdCache === String(null) ? null : userIdCache);
  const [userDetails, setUserDetails] = useState(userDetailsCache === String(null) ? null : JSON.parse(userDetailsCache));
  const [page, setPage] = useState(null);
  const [firebaseId,setFirebaseId] = useState(firebaseID===String(null) ? null:firebaseID)
  const [app, setApp] = useState(appCache === String(null) ? null : appCache);
  const [appinternalState,setAppInternalState] = useState();
  const [adminAppAccess,setAdminAppAccess] = useState(false);
  const [dashboardAppAccess,setDashboardAppAccess] = useState(false);
  const [userType, setUserType] = useState(userTypeCache === String(null) ? null : userTypeCache);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertType, setAlertType] = useState("error");
  const [showSystemAlertModal, setSystemAlertShowModal] = useState(false);
  const [remoteProviderTypeList, setRemoteProviderTypeList] = useState([]);
  const [tokenId, setTokenId] = useState(globalToken === String(null) ? null : globalToken);
  const [refreshId, setRefreshId] = useState(globalRefreshId === String(null) ? null : globalRefreshId);
  /* const [userEmail, setUserEmail] = useState(globalEmail === String(null)? null : globalEmail);
  const [userPassword, setUserPassword] = useState(globalPassword === String(null)? null: globalPassword); */
  const [userPermission, setUserPermission] = useState(globalPermission === String(null)? null : globalPermission);
  //const [userAccessId, setUserAccessId] = useState(userAccess === String(null) ? null : userAccess);
  const history = useHistory();
  const [footerAccess] = useState(false);
  const [alertsList,setAlertsList] = useRecoilState(AlertListNotification);
  const [unreadAlertsList,setUnReadAlertsList] = useRecoilState(unReadNotificationList)
  const setShowNotification = useSetRecoilState(ShowNotification)
  const [loginRedirect,setLoginRedirect] = useState(false)
  const [permissions,setPermissionArray] = useRecoilState(permission);
  const setPermissionPagesArray = useSetRecoilState(PermissionCollection)
  const hospitalManagementAccess = useRecoilValue(UILevelPermission('hospitalManagement'))
  const providerManagementAccess = useRecoilValue(UILevelPermission('Provider Management'))
  const patientManagementAccess = useRecoilValue(UILevelPermission('Patient Management'))
  const eConsultRecordAccess = useRecoilValue(UILevelPermission('eConsult Record'))
  const clinicianRecordAccess = useRecoilValue(UILevelPermission('patientrecordaccess')) 
  const systemAlertAccess = useRecoilValue(UILevelPermission('System Alert'))
  const adminUserManagementAccess = useRecoilValue(UILevelPermission('adminUserManagement'))
  const permissionAccess = useRecoilValue(UILevelPermission('permission'))
  const contactAdminMessageAccess = useRecoilValue(UILevelPermission('contactAdminMessage'))
  const [usersUid,setUsersUid] = useRecoilState(usersID)
  const pagelocation = useLocation();
  const retryApiGet = (url,header)=>{
    return API.get(url,header).catch(function(error){console.log(error,error.response,"Retry Fetch");if([401,500,502,504,703,704].includes(error?.response?.status)){
      return new Promise(resolve=>{
        API.post("/userEndpoints/v1/renewIdToken",{
          "token": refreshId
        })
        .then((res)=>{
          setTokenId(res.data.idToken)
          if(!res.data.idToken){

              history.push("/")

            throw error
          }else{
            resolve(API.get(url,{...header,headers:{
              Authorization:res.data.idToken
            }}))
          }
        })
        .catch((error) =>{Promise.reject(error)})
      })
    }})
  }
  const hitSubUserTypeAPI = () => {
    API.get(
      "/userEndpoints/v1/remoteprovidertyperesponse",{
        headers: {
          'Authorization': tokenId,
      }
      }
    )
      .then((res) => {
        if (res.data.status) {
          setRemoteProviderTypeList(res.data.remoteProviderTypeList);
        }
      })
      .catch(() => {
       // alert("Server did not response please try again...");
      });
  };

  useEffect(()=>{
    if(window.location.pathname !== '/'){
      history.push("/homepage")
      window.history.replaceState(null,"","/")
    } else{
      const reloadUrl =  sessionStorage.getItem("pagelocation");
      console.log(reloadUrl,"CheckReloadURL",app)
      if(reloadUrl !== null){
        if(app===ADMIN_APP &&reloadUrl!=='/homepage'){
        retryApiGet("/commonEndpoints/v1/userValidation", {
          headers: {
            'Authorization': tokenId,
          },
          params:{
            page:'Administration',
            userId:usersUid
          }
        }).then((res)=>{
          const accessAdmin = res.data.status
          if(accessAdmin === true){
            setAppInternalState(ADMIN_APP)
            setAdminAppAccess(true)
            console.log(adminPages,"adminPages INSIDE navigation")
            const permissionList = pluck('name',res?.data?.userRole)
            setPermissionArray(permissionList)
            const rolePagesArray = res?.data?.userRole?.map(({name,permissions})=>(permissions?{[name]:pluck('name',permissions)}:{}))
            const rolePagesObject = rolePagesArray?.reduce((acc,curVal)=>({...acc,...curVal}))
            setPermissionPagesArray(rolePagesObject)
            console.log(adminPages,"adminPages INSIDE navigation")


            // adminPages.some(({accessible,route})=>{
            //   if(accessible){
            //     history.replace({pathname:route})
            //     return false
            //   }return true
            // })

          }            else{
            setLoginRedirect(true)
          }
        }).catch(()=>{setLoginRedirect(true)})}else if(app===DASHBOARD_APP&&reloadUrl!=='/homepage'){
          retryApiGet("/commonEndpoints/v1/userValidation", {
            headers: {
              'Authorization': tokenId,
            },
            params:{
              page:'Operational Dashboard',
              userId:usersUid
            }
          }).then((res)=>{
            const accessDash = res.data.status
            if(accessDash === true){
              setAppInternalState(DASHBOARD_APP)
              setDashboardAppAccess(true)
              const permissionList = pluck('name',res?.data?.userRole)
              setPermissionArray(permissionList)
              const rolePagesArray = res?.data?.userRole?.map(({name,permissions})=>(permissions?{[name]:pluck('name',permissions)}:{}))
              const rolePagesObject = rolePagesArray?.reduce((acc,curVal)=>({...acc,...curVal}))
              setPermissionPagesArray(rolePagesObject)
              // adminPages.some(({accessible,route})=>{
              //   if(accessible){
              //     history.replace({pathname:route})
              //     return false
              //   }return true
              // })

            }
            else{
              setLoginRedirect(true)
            }
          }).catch(()=>setLoginRedirect(true))}
          history.push(reloadUrl)
          console.log("Execution_one")

      }

    }
  }, [history])

  useEffect(()=>{
    sessionStorage.setItem("pagelocation", pagelocation.pathname)
    console.log(pagelocation,"Page_Location")
    //sessionStorage.setItem("appName", app)
  }, [pagelocation])
  /* useEffect(()=>{
    if(userEmail && userPassword){
    firebase.auth().signInWithEmailAndPassword(userEmail, userPassword)
      .then((res) => {
        console.log('...res..', res)
        console.log('...res..', res.user.za)
        console.log('...refreshToken..', res.user.refreshToken)
        setRefreshId(res.user.refreshToken)
        firebase.auth().currentUser.getIdToken( true).then(function(idToken) {
          // Send token to your backend via HTTPS
          // ...
          setTokenId(idToken)

        }).catch((error) => {
          // Handle error
          console.log('firebase expiration', error)
        });
      })
      .catch(() => {});
    }
  },[userEmail,userPassword]); */

  useLayoutEffect(() => {
    // const permission = localStorage.getItem('permission')
    // if(permission!==String(null)){
    //   const permissionValue = JSON.parse(permission)
    //   setPermission(permissionValue)
    // }
    // const userId = localStorage.getItem("userId");
    // const app = localStorage.getItem("app");
    // const userType = localStorage.getItem("userType");
    // const userDetails = localStorage.getItem("userDetails");
    // console.log(app, "CheckOne");
    // setUserId(userId === String(null) ? null : userId);
    // setApp(app === String(null) ? null : app);
    // setUserType(userType === String(null) ? null : userType);
    // setUserDetails(
    //   userDetails === String(null) ? null : JSON.parse(userDetails)
    // );
    hitSubUserTypeAPI();
  }, []);

  useEffect(()=>{
    console.log(userId,firebaseId,"check the flow of userId")
    let dataBase;
    if(Boolean(userId)&&Boolean(firebaseId)){
        localStorage.setItem('loggedInTime',String(new Date().getTime()))
        console.log("Entered Database")
        try{
        let childAddedExecution = false
        dataBase = Firebase_APP.database(REACT_APP_databaseURL)
        dataBase.ref(`adminNotification/${firebaseId}`).once('value',(snapshot)=>{
          childAddedExecution = true
          console.log(snapshot.val(),"check the Value")
          Boolean(snapshot.val())&&setAlertsList(Object.values(snapshot.val()))
        })
        dataBase.ref(`adminNotification/${firebaseId}`).on('child_added',(snapshot)=>{
          console.log(childAddedExecution,"ChildAddedExecution")

          //setAlertsList([...alertsList,snapshot.val()])
          if(childAddedExecution){
            console.log(snapshot.val(),"SNAPSHOT")
          if(location.pathname==='/app/notifications'){
            let snapShotObject = snapshot.val();
            if(snapshot.key === 'Remote Provider notification'){
              snapShotObject=Object.assign(snapShotObject,{time:new Date().getTime()})
            }
            setAlertsList((alertsList)=>[...alertsList,snapShotObject])
            setShowNotification(snapShotObject)
          }
          else{
            let snapShotObject = snapshot.val();
            if(snapshot.key === 'Remote Provider notification'){
              snapShotObject=Object.assign(snapShotObject,{time:new Date().getTime()})
            }
            console.log(snapshot.val(),"SNAPSHOT1")
          setUnReadAlertsList((unreadAlertsList)=>[...unreadAlertsList,snapShotObject])
          setShowNotification(snapShotObject)
          }}
          // const AlertsList = snapshot.val();
          // setAlertsList(AlertsList)
          //setAlertsList([...alertsList,snapshot.val()])
      })
      dataBase.ref(`adminNotification/${firebaseId}`).on('child_removed',(snapshot)=>{
        const childRemoved = snapshot.val();
        const removedKey = snapshot.key;
        console.log(removedKey,'removedKey')
        if(removedKey === 'Remote Provider notification'){
          setUnReadAlertsList(unreadAlertsList=>{
            return unreadAlertsList.filter((alertListItem)=>alertListItem.hasOwnProperty('Patient'))
          })
          setAlertsList(alertsList=>{
            return alertsList.filter((alertListItem)=>alertListItem.hasOwnProperty('Patient'))
          })
        }else{
        const {Patient:removedPatient} = childRemoved;
        const {id:removedID} = JSON.parse(removedPatient);
        console.log("Child Removed",removedID)
        const findFunction = findIndex(({Patient})=>{
          const {id} = JSON.parse(Patient)
          console.log(id,removedID,"Comparison")
          return id===removedID
        })
        setUnReadAlertsList(unreadAlertsList=>{
          if(findFunction(unreadAlertsList)!==-1){
            return remove(findFunction(unreadAlertsList),1,unreadAlertsList)
          }
          return unreadAlertsList
        })
        setAlertsList(alertsList=>{
          if(findFunction(alertsList)!==-1){
            return remove(findFunction(alertsList),1,alertsList)
          }
          return alertsList
        })
        // console.log(childAddedExecution,"ChildAddedExecution")

        // //setAlertsList([...alertsList,snapshot.val()])
        // if(childAddedExecution){
        // setUnReadAlertsList([...unreadAlertsList,snapshot.val()])
        // }
        // const AlertsList = snapshot.val();
        // setAlertsList(AlertsList)
        //setAlertsList([...alertsList,snapshot.val()])
    }})
    }
      catch(error){
          
      }
    }return ()=>{
      if(dataBase?.ref(`adminNotification/${firebaseId}`)){
        dataBase.ref(`adminNotification/${firebaseId}`).off('value')
        dataBase.ref(`adminNotification/${firebaseId}`).off('child_added')
        dataBase.ref(`adminNotification/${firebaseId}`).off('child_removed')
      }
    }
  },[userId,firebaseId,location])

  useEffect(() => {
    localStorage.setItem("userId", String(userId));
    localStorage.setItem("app", String(app));
    localStorage.setItem("userType", String(userType));
    localStorage.setItem("userDetails", JSON.stringify(userDetails));
    localStorage.setItem("tokenId", String(tokenId));
    localStorage.setItem("refreshId", String(refreshId));
    /* localStorage.setItem("userEmail", String(userEmail));
    localStorage.setItem("userPassword", String(userPassword)); */
    localStorage.setItem("userPermission", String(userPermission));
    localStorage.setItem("firebaseID",String(firebaseId))
    console.log(tokenId,"Token_ID")
    localStorage.setItem("usersuid",usersUid)
    console.log(tokenId,"Token_ID")
    if(!Boolean(userId)){
      localStorage.removeItem("userId")
    }
    if(!Boolean(app)){
      localStorage.removeItem("app")
    }
    if(!Boolean(userDetails)){
      localStorage.removeItem("userDetails")
    }
    if(!Boolean(tokenId)){
      localStorage.removeItem("tokenId")
    }
    if(!Boolean(refreshId)){
      localStorage.removeItem("refreshId")
    }
    if(!Boolean(userPermission)){
      localStorage.removeItem("userPermission")
    }
    if(!Boolean(firebaseId)){
      localStorage.removeItem("firebaseID")
    }
    if(!Boolean(usersUid)){
      localStorage.removeItem("usersuid")
    }
  }, [userId, app, userDetails, tokenId, refreshId, userPermission, firebaseId,usersUid]);

  useEffect(() => {
    setPage(location.pathname);
  }, [location]);

  useEffect(()=>{
    console.log(unreadAlertsList,"UNREADALERTSLIST")
  },[unreadAlertsList])



  const theme = createMuiTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#9B51E0",
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#11cb5f",
      },
      success: {
        // This is green.A700 as hex.
        main: "#cb114c",
      },
    },
  });

  const PrivateRoute = ({
    children,
    isCurrentApp,
    userId,
    component,
    location,
    ...rest
  }) => {
    return (
      <Route
        {...rest}
        component={
          userId != null && isCurrentApp
            ? component
            : () => (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: location },
                  }}
                />
              )
        }
      />
    );
  };
  const alertClose = () => {
    setAlertOpen(false);
    setAlertText("");
    setAlertType("error");
  };
  const alert = (text, type) => {
    console.log("...text...", text);
    console.log("...text...", type);
    setAlertText(text);
    type && setAlertType(type);
    setAlertOpen(true);
  };

  const adminPages = [
    {
      name: "Hospital Management",
      route: "/app/hospital",
      icon: <LocalHospitalIcon />,
      accessible:hospitalManagementAccess
    },
    {
      name: "Provider Management",
      route: "/app/approvals",
      icon: <PeopleAltIcon />,
      accessible:providerManagementAccess
    },
    {
      name: "Patient Management",
      route: "/app/patients",
      icon: <AccessibilityIcon />,
      accessible:patientManagementAccess
    },
    {
      name: "eConsult Record",
      route: "/app/patients/medicalrecord",
      icon: <LocalHospitalIcon />,
      accessible:eConsultRecordAccess
    },
    {
      name: "Admin User Management",
      route: "/app/adminusers",
      icon: <AccountCircleIcon />,
      accessible:adminUserManagementAccess
    },
    {
      name: "System Alert",
      route: "/app/alert",
      icon: <NotificationImportantIcon />,
      accessible:systemAlertAccess
    },
    {
      name: "Permission",
      route: "/app/permissions",
      icon: <PolicyIcon />,
      accessible:permissionAccess
    },
    {
      name: "Clinician signout",
      route: "/app/clinician",
      icon: <NotificationImportantIcon />,
      accessible:clinicianRecordAccess
    }
  ];
  const triagePages = [
    {
      name: "Patient Management",
      route: "/app/patients",
      icon: <HomeWorkIcon />,
    } /* {
            name: 'Patient Upload',
            route: '/app/patientsupload',
            icon:<AccessibilityIcon/>
        }*/,
  ];

  const dashboardPages = [
    {
      name: "Dashboard",
      route: "/dashboard",
      icon: <DashboardIcon />,
      accessible:true
    },
    {
      name: "Active Consults",
      route: "/dashboard/active_consults",
      icon: <QuestionAnswerIcon />,
      accessible:true
    },
    {
      name: "Pending Consults",
      route: "/dashboard/pending_consults",
      icon: <QuestionAnswerIcon />,
      accessible:true
    },
    {
      name: "Wards",
      route: "/dashboard/wards",
      icon: <LocalHospitalIcon />,
      accessible:true
    },
    {
      name: "Staffing",
      route: "/dashboard/staffing",
      icon: <SupervisedUserCircleIcon />,
      accessible:true
    } /*  {
            name: 'Reports',
            route: '/dashboard/reports',
            icon:<AssessmentIcon/>
        }, */,
  ];
  useEffect(()=>{
    if(!isEmpty(permissions)){
      if(pagelocation.pathname==='/homepage'){
      console.log(adminPages,"adminPages")
      if(app==='adminApp'){
              adminPages.some(({accessible,route})=>{
        if(accessible){
          console.log('Execution_two',route)
          history.replace({pathname:route})
          return true
        }return false
      })
      }else if(app==='dashboardApp'){
              dashboardPages.some(({accessible,route})=>{
                console.log('Execution_two')
        if(accessible){
          history.replace({pathname:route})
          return true
        }return false
      })
      }

      console.log(adminPages,"InsidePermissions")
    }else{

    }
  }
  },[permissions])
  const renderSwitch = useMemo(
    () => (
      <div className="rootPadding">
        <CustomAlert
          open={alertOpen}
          handleClose={alertClose}
          text={alertText}
          type={alertType}
        />
        <Switch>
          {/* {userId !== null ? ():()} */}
          {Boolean(userId) ? (<>
              <>{appinternalState === ADMIN_APP && adminAppAccess && userType !== "T" && (<AdminApp
                    app={app}
                    userType={userType}
                    userId={userId}
                    alert={alert}
                    setUserId={setUserId}
                    tokenId={tokenId}
                    setTokenId={setTokenId}
                    refreshId={refreshId}
                    setRefreshId={setRefreshId}
                    remoteProviderTypeList={remoteProviderTypeList}
                    userDetails={userDetails}
                  />)}</><>{
                    appinternalState === ADMIN_APP && userType === "T" && (<TriageApp
                      app={app}
                      userId={userId}
                      setUserId={setUserId}
                      alert={alert}
                      tokenId={tokenId}
                      setTokenId={setTokenId}
                      refreshId={refreshId}
                      setRefreshId={setRefreshId}/>)
                  }</><>{
                    appinternalState === DASHBOARD_APP && dashboardAppAccess && (
                      <DashboardApp
                      app={app}
                      userId={userId}
                      setUserId={setUserId}
                      alert={alert}
                      tokenId={tokenId}
                      setTokenId={setTokenId}
                      refreshId={refreshId}
                      setRefreshId={setRefreshId}/>
                    )
                  }</></>):(<div style={{ fontSize: "22px" }}>
                  Please{" "}
                  <a
                    style={{
                      textDecoration: "underline",
                      color: "#9B51E0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.push("/login");
                    }}
                  >
                    Login
                  </a>{" "}
                  to access the application
                </div>)}
        </Switch>
      </div>
    ),
    [alertOpen, alertText, alertType, alert, userId, userType, userDetails,appinternalState,adminAppAccess,dashboardAppAccess]
  );

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Switch>
        <Route
          path="/"
          exact
          component={() => (
            <Index
              redirectionURL={
                userId ? "/homepage"
                  : "/login"
              }
            />
          )}
        />
        <Route
          path="/login"
          exact
          render={() => {if(userId){
            return (<Redirect to="/homepage" />)
          }
            return (
            <div style={{ display: "grid", height: "80vh" }}>
              <LoginScreen
                alert={alert}
                userId={userId}
                setUserId={setUserId}
                app={app}
                setApp={setApp}
                userType={userType}
                setUserType={setUserType}
                setUserDetails={setUserDetails}
                tokenId={tokenId}
                setTokenId={setTokenId}
                refreshId={refreshId}
                setRefreshId={setRefreshId}
                /* setUserEmail={setUserEmail}
                setUserPassword={setUserPassword} */
                setUserPermission={setUserPermission}
                userPermission={userPermission}
                setFirebaseId={setFirebaseId}
              />
            </div>
          )}}
        />
        <Route path="/app/forgotpassword"
        isCurrentApp={app === ADMIN_APP && userType !== "T"}
        component={() => (
          <ForgotPasswordScreen
            setUserId={setUserId}
            userId={userId}
            tokenId={tokenId}
            setTokenId={setTokenId}
            refreshId={refreshId}
            setRefreshId={setRefreshId}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            app={app}
            setApp={setApp}
            userType={userType}
            setUserType={setUserType}
          />
        )} />
        <Route path="/error" exact render={(props)=> <ErrorScreen />} />
        <Route path="/homepage" exact render={(props)=> <NavigationComponent alert={alert}
                adminPages={adminPages}
                dashboardPages={dashboardPages}
                userId={userId}
                setUserId={setUserId}
                tokenId={tokenId}
                setTokenId={setTokenId}
                refreshId={refreshId}
                setRefreshId={setRefreshId}
                userPermission={userPermission}
                setUserPermission={setUserPermission}
                app={app}
                setApp={setApp}
                userType={userType}
                setUserType={setUserType}
                refreshId={refreshId}
                setAppInternalState={setAppInternalState}
                setAdminAppAccess={setAdminAppAccess}
                setDashboardAppAccess={setDashboardAppAccess}
                setUserDetails={setUserDetails} setUsersUid={setUsersUid} setFirebaseId={setFirebaseId} {...props} /> } />
              <Route
        path="/app/changePassword"
        isCurrentApp={app === ADMIN_APP && userType !== "T"}
        component={() => (
          <ChangePasswordScreen
            setUserId={setUserId}
            userId={userId}
            tokenId={tokenId}
            setTokenId={setTokenId}
            refreshId={refreshId}
            setRefreshId={setRefreshId}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            app={app}
            setApp={setApp}
            userType={userType}
            setUserType={setUserType}
          />
        )}
      />
      <Route
        path="/app/success"
        isCurrentApp={app === ADMIN_APP && userType !== "T"}
        component={() => (
          <SuccessScreen setUserId={setUserId} userId={userId} />
        )}
      />
        {Boolean(appinternalState) && PagesList[appinternalState]?.includes(page) &&  (
          <div>
            <Suspense
              fallback={
                <div
                  style={{
                    height: "80vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="loader"></div>
                </div>
              }
            >
              <ResponsiveDrawer
                path={location.pathname}
                authUser={userId}
                shouldShowDrawer={page !== LOGIN_SCREEN && app != null}
                setUserDetails={setUserDetails}
                setUserId={setUserId}
                setApp={setApp}
                setUserPermission={setUserPermission}
                setTokenId={setTokenId}
                setRefreshId={setRefreshId}
                setUserType={setUserType}
                setFirebaseId={setFirebaseId}
                setUsersUid={setUsersUid}
                pages={
                  app === ADMIN_APP
                    ? userType !== "T"
                      ? adminPages
                      : triagePages
                    : dashboardPages
                }
                pageValue={page}
                app={app}
                onPageChange={(value) => {
                  history.push(value);
                }}
                children={renderSwitch}
              />
            </Suspense>
          </div>
        )}
        <Route render={(props)=>{

          return (<RoutingComponent alert={alert}
            userId={userId}
            setUserId={setUserId}
            app={app}
            setApp={setApp}
            userType={userType}
            setUserType={setUserType}
            setUserDetails={setUserDetails}
            setUsersUid={setUsersUid}
            setFirebaseId={setFirebaseId}
            setUserPermission={setUserPermission}
            setRefreshId={setRefreshId}
            setTokenId={setTokenId} loginRedirect={loginRedirect} {...props} /> )
        }} />
        </Switch>
        {showSystemAlertModal && (
          <SystemAlertScreen
            handleClose={() => setSystemAlertShowModal(false)}
          />
        )}
        <NotificationComponent />
        <footer>
          <div className="footerSection">
            <div className="footerBody">
              <Typography variant="body1">
                <Link
                  href="https://www.omnicuremd.com/terms.html"
                  target="_blank"
                  className="footerText"
                  color="primary"
                >
                  Terms & Conditions
                </Link>
              </Typography>
              <Typography variant="body1">
                <Link
                  href="https://www.omnicuremd.com/contact.html"
                  target="_blank"
                  className="footerText"
                  color="primary"
                >
                  Contact Us
                </Link>
              </Typography>

              <Copyright />
            </div>
          </div>
        </footer>
      </div>
    </ThemeProvider>
  );
};

const Index = ({ redirectionURL }) => {
  return <Redirect to={redirectionURL} />;
};

export default withRouter(App);

//##ORIGINAL ROUTING CODE FOR REFERENCE

// <Switch>
//                 <Route path="/" exact component={Index}/>
//                 <Route path="/login" exact
//                        component={() => <LoginScreen alert={alert} userId={userId} setUserId={setUserId} app={app}
//                         setApp={setApp} userType={userType} setUserType={setUserType} setUserDetails={setUserDetails}/>}/>
//                 <Route path="/app/changePassword" isCurrentApp={app === ADMIN_APP && userType !== 'T'} component={() => <ChangePasswordScreen setUserId={setUserId} userId={userId} userDetails={userDetails}/>} />
//                 <Route path="/app/success" isCurrentApp={app === ADMIN_APP && userType !== 'T'} component={() => <SuccessScreen setUserId={setUserId} userId={userId}/>} />

//                 <Route path="/app/forgetPassword" component={PasswordComponent} />

//                 <Route path="/app/sign-up" component={PasswordComponent} />
//                 {userId !==null? <>
//                 <Route  path="/app/hospital" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={HospitalScreen}/>

//                 <Route  path="/app/adminusers" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={AdminUserScreen}/>

//                 <PrivateRoute isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId}
//                               path="/app/approvals" exact
//                               component={() => <ApprovalScreen alert={alert}
//                                                                remoteProviderTypeList={remoteProviderTypeList}/>}/>
//                 <Route  path="/app/patients/medicalrecord" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={MedicalRecordScreen} />

//                 <Route  path="/app/patients" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={PatientScreen}/>

//                 <Route  path="/app/alert" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={SystemAlertScreen}/>

//                 <Route  path="/app/" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId}  exact component={PatientUploadScreen} alert={alert}/>

//                 {/* <PrivateRoute isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} path="/app/patientsupload"
//                               exact
//                               componnet={() => <PatientUploadScreen alert={alert}/>}/> */}

//                {/*  <Route  path="/app/patientsupload" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={PatientUploadScreen}/> */}

//                  {/* <Route  path="/app/adminusers" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={AdminUserScreen}/>
//                 <Route  path="/app/approvals" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={ApprovalScreen}/>
//                 <Route  path="/app/patients" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={PatientScreen}/>
//                 <Route  path="/app/alert" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={SystemAlertScreen}/>
//                 <Route  path="/app/patientsupload" isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} alert={alert} exact component={PatientUploadScreen}/> */}

//                 {/* <PrivateRoute isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId}
//                               path="/app/hospital" exact
//                               component={() => <HospitalScreen alert={alert}/>}/> */}
//                {/*  <PrivateRoute isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId}
//                               path="/app/adminusers" exact
//                               component={() => <AdminUserScreen alert={alert}/>}/> */}

//                 {/* <PrivateRoute isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId}
//                               path="/app/approvals" exact
//                               component={() => <ApprovalScreen alert={alert}
//                                                                remoteProviderTypeList={remoteProviderTypeList}/>}/> */}

//                {/*  <PrivateRoute isCurrentApp={app === ADMIN_APP} userId={userId} path="/app/patients" exact
//                               component={() => <PatientScreen alert={alert}/>}/> */}

//                 {/* <PrivateRoute isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} path="/app/alert"
//                               exact
//                               component={() => <SystemAlertScreen alert={alert}/>}/> */}
//                {/*  <PrivateRoute isCurrentApp={app === ADMIN_APP && userType !== 'T'} userId={userId} path="/app/patientsupload"
//                               exact
//                               component={() => <PatientUploadScreen alert={alert}/>}/> */}

//                 {/*dashboard screens*/}
//                 <Route  path="/dashboard" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={DashboardScreen}/>

//                 <Route  path="/dashboard/active_consults" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={ActiveConsultsScreen}/>

//                 <Route  path="/dashboard/pending_consults" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={PendingConsultsScreen}/>

//                 <Route  path="/dashboard/staffing" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={StaffingScreen}/>

//                 <Route  path="/dashboard/wards" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={WardsScreen}/>

//                 <Route  path="/dashboard/reports" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={ReportScreen}/>

//                {/*  <PrivateRoute isCurrentApp={app === DASHBOARD_APP} userId={userId} path="/dashboard" exact
//                               component={() => <DashboardScreen alert={alert}/>}/> */}
//                 {/* <PrivateRoute isCurrentApp={app === DASHBOARD_APP} userId={userId} path="/dashboard/active_consults"
//                               exact
//                               component={() => <ActiveConsultsScreen alert={alert}/>}/> */}
//                {/*  <PrivateRoute isCurrentApp={app === DASHBOARD_APP} userId={userId}
//                               path="/dashboard/pending_consults" exact
//                               component={() => <PendingConsultsScreen alert={alert}/>}/> */}
//                 {/* <PrivateRoute isCurrentApp={app === DASHBOARD_APP} userId={userId} path="/dashboard/wards" exact
//                               component={() => <WardsScreen alert={alert}/>}/> */}
//                {/*  <PrivateRoute isCurrentApp={app === DASHBOARD_APP} userId={userId}
//                               path="/dashboard/staffing" exact
//                               component={() => <StaffingScreen alert={alert}/>}/> */}
//                 {/* <PrivateRoute isCurrentApp={app === DASHBOARD_APP} userId={userId}
//                               path="/dashboard/reports" exact
//                               component={() => <ReportScreen alert={alert}/>}/> */}
//                 {/* --------------Dashboard Root-------- */}

//                 {/* <Route  path="/dashboard/active_consults" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={ActiveConsultsScreen}/>
//                 <Route  path="/dashboard/pending_consults" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={PendingConsultsScreen}/>
//                 <Route  path="/dashboard/wards" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={WardsScreen}/>
//                 <Route  path="/dashboard/staffing" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={StaffingScreen}/>
//                 <Route  path="/dashboard/reports" isCurrentApp={app === DASHBOARD_APP} userId={userId} alert={alert} exact component={ReportScreen}/> */}</>: <><div style={{fontSize:'22px'}}>Please <a style={{textDecoration: 'underline', color:'#9B51E0', cursor:'pointer'}} onClick={() => {
//                     history.push('/login')
//                 }}>Login</a> to access the application</div></>}
//             </Switch>
