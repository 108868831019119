import axios from "axios";

const { REACT_APP_API } = process.env;

const API = axios.create({
  baseURL: '/api' //REACT_APP_API,
});

export default API;
// baseURL: `https://dev-omnicure.appspot.com/_ah/api`
//  baseURL: `https://omnicure-qa.appspot.com/_ah/api`
//baseURL: `https://omnicure-demo.appspot.com/_ah/api`
// baseURL: `https://omnicure-staging.appspot.com/_ah/api`
//  baseURL: `https://omnicurepilot.appspot.com/_ah/api`
// baseURL: `https://omnicure.appspot.com/_ah/api`
// baseURL: `https://exttesting.appspot.com/_ah/api`
