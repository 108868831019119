import React,{useEffect,useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import oc_logo from "../../assets/images/omnicure_logo.svg";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import HomeIcon from '@material-ui/icons/Home';
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from 'axios';
import { Grid, Card, CardActionArea, Collapse, IconButton, AppBar, Toolbar } from '@material-ui/core';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import {UILevelPermission,permission,PermissionCollection,usersID} from 'App';
import {useRecoilValue,useSetRecoilState,useRecoilState} from 'recoil';
import {
    ACTIVE_CONSULT_SCREEN,
    ADMIN_APP,
    ADMIN_USER_SCREEN,
    APPROVAL_SCREEN,
    DASHBOARD_APP,
    DASHBOARD_SCREEN,
    HOSPITAL_SCREEN,
    LOGIN_SCREEN,
    PATIENT_SCREEN,
    PENDING_CONSULT_SCREEN,
    REPORT_SCREEN,
    STAFFING_SCREEN,
    SYSTEM_ALERT,
    WARDS_SCREEN,
  } from "../../AppConstants";
  import API from "../../api"
  import ExpiredMessage from "../common/ExpiredMessage";
  import LoginLoader from "../common/LoginLoader";
import {pluck} from 'ramda';
import createAuthRefreshInterceptor from "axios-auth-refresh";

const administrationAccessPrecedence = ['superAdmin','enterpriseAdmin','supportadminAccess']

const dashboardAccessPrecedence = ['superAdmin','dashboardSuperAdmin','dashboardUserAdmin']

  const useAvatarStyles = makeStyles((theme)=>({
    colorDefault:{
      backgroundColor:"rgba(0, 0, 0, 0.54)"
    },
    root:{
      marginRight:10
    }
  }))

const useStylesHtml = makeStyles((theme)=>({
    header:{
        width:'100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between',
        height:60,
        background:'#fff'
    },
    iconStyle: {
        fontSize: "2rem",
        marginRight: "10px",
        color:"rgba(0, 0, 0, 0.54)"
        // margin: '5px 0px 5px 5px'
      },
      menuList: {
        padding: "0px 8px !important",
      },
      card:{
          width:'80%',
          height:188,
          transition: 'box-shadow .3s',
          fontSize:18,
          [theme.breakpoints.down('md')]: {
            width: '60%',
            margin:12
          },
          ['@media (min-height:800px)']:{
            height:'25vh',
            width: '80%'
          }
      },
      cardHover:{
        '&:hover':{
            boxShadow: '0 0 11px #9B51E0',
            fontWeight: 'bold'
          }
      },
      cardActionArea:{
          width:'100%',
          height:'100%',
          padding:10,
          fontSize:18,
          '&:hover':{
              fontWeight:'bold'
          },
          '&:disabled':{
              backgroundColor:'#ebebeb',
              color:'grey'
          }
      },
      alertContainer:{
        position:'absolute',
        width:'100%',
        display:'flex',
        justifyContent:'center',
        top:80
      },
      toolBar:{
        display:'flex',
        justifyContent:'space-between'
      },
      menuList: {
        padding: "0px 8px !important",
      },
      menuItem: {
        margin: "65px 0 0 0",
      },
}))

const NavigationComponent = ({
    userId,
    setUserId,
    app,
    setApp,
    setUserType,
    userType,
    setUserDetails,
    history,
    userPermission,
    setUserPermission,
    tokenId,
    setTokenId,
    refreshId,
    setRefreshId,
    adminPages,
    dashboardPages,
    setAppInternalState,
    setAdminAppAccess,
    setDashboardAppAccess,
    setFirebaseId,
    setUsersUid
  }) =>{
    const [anchorEl, setAnchorEl] = useState(null);
    const [avatoranchorEl,setAvatarAnchorEl] = useState(null);
    const avatarStyles = useAvatarStyles();
    const [open, setOpen] = useState(false)
    const userEmail = localStorage.getItem('userId');
    const userDetailsJSON = localStorage.getItem('userDetails')
    const [alertMSG,setAlertMSG] = useState('')
    console.log(userDetailsJSON,"userDetailsJSON")
    const userDetails = JSON.parse(Boolean(userDetailsJSON)||userDetailsJSON!=='null'?userDetailsJSON:{})
    const [expiredMsg, setExpiredMsg] = useState(false);
    const [expiredLogin, setexpiredLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [homeCallBack, setHomeCallBack] = useState("")
    const adminAccess = useRecoilValue(UILevelPermission("adminAccess"))
    const dashboardAccess = useRecoilValue(UILevelPermission("dashboardAccess"))
    const webPortalAccess = useRecoilValue(UILevelPermission("webPortalAccess"))
    const setPermissionArray = useSetRecoilState(permission)
    const setPermissionPagesArray = useSetRecoilState(PermissionCollection)

  const usersUid = useRecoilValue(usersID)
    console.log(adminAccess,dashboardAccess,webPortalAccess,open,"checkPermissions_Navigation")
    const handleAvatarMenuClick = (event) => {
      setAvatarAnchorEl(event.currentTarget);
    };
    const handleAvatarMenuClose = () => {
      setAvatarAnchorEl(null);
    }
    const signOut = () => {
        //localStorage.removeItem('userId','userDetails','app','userId')
        setUserId(null);
        setUserDetails(null);
        setApp(null);
        setUsersUid(null)
        setFirebaseId(null)
        setUserPermission(null)
        //setUserAccessId(null)
        setRefreshId(null)
        setTokenId(null)
        history.replace("/");
      };
      const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
      }

      const handleMenuClose = () => {
        setAnchorEl(null);
      }
      const changePasswordScreen = () => {
        history.push("/app/changePassword");
      }

const HTMLClasses = useStylesHtml();

const retryApiGet = (url,header)=>{
  return API.get(url,header).catch(function(error){console.log(error,error.response,"Retry Fetch");if([401,500,502,504,703,704].includes(error?.response?.status)){
    return new Promise(resolve=>{
      API.post("/userEndpoints/v1/renewIdToken",{
        "token": refreshId
      })
      .then((res)=>{
        setTokenId(res.data.idToken)
        if(!res.data.idToken){
          setExpiredMsg(true)
          if(expiredLogin){
            setUserId(null)
            history.push("/")
          }
          throw error
        }else{
          resolve(API.get(url,{...header,headers:{
            Authorization:res.data.idToken
          }}))
        }
      })
      .catch((error) =>{Promise.reject(error)})
    })
  }})
}

const retryApiPost = (url,data,header)=>{
  return API.post(url,data,header).catch(function(error){console.log(error,error.response,"Retry Fetch");if([401,500,502,504,703,704].includes(error?.response?.status)){
    return new Promise(resolve=>{
      API.post("/userEndpoints/v1/renewIdToken",{
        "token": refreshId
      })
      .then((res)=>{
        setTokenId(res.data.idToken)
        if(!res.data.idToken){
          setExpiredMsg(true)
          if(expiredLogin){
            setUserId(null)
            history.push("/")
          }
          throw error
        }else{
          resolve(API.post(url,data,{headers:{
            Authorization:res.data.idToken
          }}))
        }
      })
      .catch((error) =>{Promise.reject(error)})
    })
  }})
}

// useEffect(()=>{
//   const refreshAuthLogic = failedRequest =>
//   API.post("/userEndpoints/v1/renewIdToken",{
//     "token": refreshId
//   })
//   .then((res)=>{
//     setTokenId(res.data.idToken)
//     if(!res.data.idToken){
//       setExpiredMsg(true)
//       if(expiredLogin){
//         setUserId(null)
//         history.push("/")
//         return Promise.reject();
//       }
//       return Promise.reject();
//     }
//     failedRequest.response.config.headers["Authorization"] = res.data.idToken;
//   return Promise.resolve();
//   })
//   .catch((error) =>{
//     console.log("refresh fail");
//     return Promise.reject(error);
//   })
//   createAuthRefreshInterceptor(API, refreshAuthLogic,{statusCodes:[401,500,502,504,703,704]});
// },[])

useEffect(()=>{
  if(!userId){
      history.replace({pathname:'/login'})
  }
},[userId])

useEffect(()=>{
  if(!open){
    setAlertMSG('')
  }
},[open])

useEffect(()=>{
  //console.log(localStorage.getItem("app"),"check")
  const reloadUrl =  sessionStorage.getItem("pagelocation");
  if(reloadUrl && reloadUrl === "/homepage"){
    app&&setApp(null)
    localStorage.removeItem('app')
    setAppInternalState(null)
    setAdminAppAccess(false)
    setDashboardAppAccess(false)
  }
},[])

useEffect(()=>{
  if(app===ADMIN_APP){
      //history.replace({pathname:HOSPITAL_SCREEN})
  }
  else if(app===DASHBOARD_APP){
      //history.replace({pathname:DASHBOARD_SCREEN})
  }
},[app])

const refreshToken = () => {
  API.post("/userEndpoints/v1/renewIdToken",{
    "token": refreshId
  })
  .then((res)=>{
    setTokenId(res.data.idToken)
    if(!res.data.idToken){
      setExpiredMsg(true)
      if(expiredLogin){
        setUserId(null)
        history.push("/")
      }
    }
    console.log('homeCallBack....', homeCallBack)
    if(homeCallBack && homeCallBack === 'Administration'){
      AdminNavHandler();
    } else if(homeCallBack && homeCallBack === 'Dashboard'){
      DashboardNavHandler();
    }
  })
  .catch((error) =>{})
}
useEffect(() =>{
  if(expiredLogin){
    setUserId(null)
    history.push("/")
  }
},[expiredLogin])

const AdminNavHandler = () => {
  setHomeCallBack("Administration")
  setOpen(false)
  setLoading(true)
  retryApiGet("/commonEndpoints/v1/userValidation", {
    headers: {
      'Authorization': tokenId,
    },
    params:{
      page:'Administration',
      userId:usersUid
    }
  })
  .then((res)=>{
    setLoading(false)
    const accessAdmin = res.data.status
    if(accessAdmin === true){
      setApp(ADMIN_APP)
      setAppInternalState(ADMIN_APP)
      setAdminAppAccess(true)
      console.log(adminPages,"adminPages INSIDE navigation")
      const permissionList = pluck('name',res?.data?.userRole)
      setPermissionArray(permissionList)
      const rolePagesArray = res?.data?.userRole?.map(({name,permissions})=>(permissions?{[name]:pluck('name',permissions)}:{}))
      const rolePagesObject = rolePagesArray?.reduce((acc,curVal)=>({...acc,...curVal}))
      setPermissionPagesArray(rolePagesObject)
      console.log(adminPages,"adminPages INSIDE navigation")
      // adminPages.some(({accessible,route})=>{
      //   if(accessible){
      //     history.replace({pathname:route})
      //     return false
      //   }return true
      // })

    } else{
      setOpen(true)
      setAlertMSG(res?.data?.errorMessage)
    }

  }).then(()=>{
    console.log("ADMIN_APP",app)
  })
  .catch((error)=>{
    setLoading(false)
    if (error&&(error.response && error.response.status === 703) ||(error.response && error.response.status === 704)) {
      //refreshToken();
    } else if(error&&error.response && error.response.status === 504){
      //refreshToken();
    } else if(error&&error.response && error.response.status === 500){
      //refreshToken();
    } else if(error&&error.response && error.response.status === 502){
      //refreshToken();
    }
  })
}

const DashboardNavHandler = () => {
  console.log(dashboardAccess,"Check the dashboard Access")
  setHomeCallBack("Dashboard")
  setOpen(false)
  setLoading(true)
  retryApiGet("/commonEndpoints/v1/userValidation", {
    headers: {
      'Authorization': tokenId,
    },
    params:{
      page:'Operational Dashboard',
      userId:usersUid
    }
  })
  .then((res)=>{
    setLoading(false)
    const accessDash = res.data.status
    if(accessDash === true){
      setApp(DASHBOARD_APP)
      //setApp(ADMIN_APP)
      setAppInternalState(DASHBOARD_APP)
      setDashboardAppAccess(true)
      const permissionList = pluck('name',res?.data?.userRole)
      setPermissionArray(permissionList)
      const rolePagesArray = res?.data?.userRole?.map(({name,permissions})=>(permissions?{[name]:pluck('name',permissions)}:{}))
      const rolePagesObject = rolePagesArray?.reduce((acc,curVal)=>({...acc,...curVal}))
      setPermissionPagesArray(rolePagesObject)
      //history.replace({pathname:DASHBOARD_SCREEN})
      // dashboardPages.some(({accessible,route})=>{
      //   if(accessible){
      //     history.replace({pathname:route})
      //     return true
      //   }return false
      // })

    } else{
      setOpen(true)
      setAlertMSG(res?.data?.errorMessage)
    }

  }).then(()=>{console.log(app,"ADMIN_APP")})
  .catch((error)=>{
    setLoading(false)
    if (error&&(error.response && error.response.status === 703) ||(error.response && error.response.status === 704)) {
      //refreshToken();
    } else if(error&&error.response && error.response.status === 504){
      //refreshToken();
    } else if(error&&error.response && error.response.status === 500){
      //refreshToken();
    } else if(error&&error.response && error.response.status === 502){
      //refreshToken();
    }
  })
}

  return (<div style={{minHeight:'calc(100vh - 64px)',display:'grid',gridTemplateRows:'1fr'}}>
      <AppBar><Toolbar className={HTMLClasses.toolBar}>
      <div>
                <img
                  style={{
                    float: "left",
                    alignSelf: "center",
                  }}
                  src={oc_logo}
                  alt={"logo"}
                  importance="low"
                />
                <div
                  style={{
                    float: "left",
                    marginLeft: "5px",
                    fontSize: "2.1rem",
                    color:"initial"
                  }}
                >
                  Omnicure
                </div></div>
                <div style={{display:'flex',alignItems:'center'}}>
                <HomeIcon className={HTMLClasses.iconStyle} color="disabled"/>
        <SettingsIcon
                aria-controls="simple-menu"
                aria-haspopup="true"
                className={HTMLClasses.iconStyle}
                onClick={handleMenuClick}
              />
                            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                style={{ margin: "55px 0 0 0" }}
              >
                <MenuItem className={HTMLClasses.menuList}>
                  <a onClick={changePasswordScreen}>Change Password</a>
                </MenuItem>
              </Menu>
                <Avatar classes={avatarStyles} onClick={handleAvatarMenuClick}>{userDetails?.fname?.[0]}</Avatar>
              <Menu
                id="simple-menu"
                anchorEl={avatoranchorEl}
                keepMounted
                open={Boolean(avatoranchorEl)}
                onClose={handleAvatarMenuClose}
                style={{ margin: "55px 0 0 0" }}
              >
                <MenuItem className={HTMLClasses.menuList}>
                  <a onClick={signOut}>Log out</a>
                </MenuItem>
              </Menu>
            </div>
        </Toolbar>
        </AppBar>
        <div style={{paddingTop:64,display:'flex',flexDirection:'column'}}>
     {/* <div className={HTMLClasses.alertContainer}> */}
      <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="primary"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" color="primary" />
                </IconButton>
              }
            >
              {alertMSG}
            </Alert>
          </Collapse>
         {/* </div> */ }
      <Grid container alignItems="center" style={{flexGrow:1}}>
          <Grid item lg={3} md={6} sm={6} xs={12} container justify="center">
            <Card className={clsx({[HTMLClasses.card]:true,[HTMLClasses.cardHover]:true})}><CardActionArea className={HTMLClasses.cardActionArea} onClick={AdminNavHandler} id="administration">Administration</CardActionArea>
            </Card>
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12} container justify="center">
            <Card className={clsx({[HTMLClasses.card]:true,[HTMLClasses.cardHover]:true})}><CardActionArea className={HTMLClasses.cardActionArea} onClick={DashboardNavHandler} id="dashboard">Operational Dashboard</CardActionArea></Card>
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12} container justify="center">
            <Card className={clsx({[HTMLClasses.card]:true,[HTMLClasses.cardHover]:false})} onClick={()=>{setOpen(true);setAlertMSG("You dont have permission to access this")}}><CardActionArea className={HTMLClasses.cardActionArea} disabled>Reports</CardActionArea></Card>
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={12} container justify="center">
            <Card className={clsx({[HTMLClasses.card]:true,[HTMLClasses.cardHover]:false})} onClick={()=>{setOpen(true);setAlertMSG("You dont have permission to access this")}}><CardActionArea className={HTMLClasses.cardActionArea} disabled>Web Portal</CardActionArea></Card>
          </Grid>
      </Grid>
      </div>
      {loading && <LoginLoader />}
      {expiredMsg && <div><ExpiredMessage expiredMsg={expiredMsg} setexpiredLogin={setexpiredLogin}/></div>}
  </div>)
}

export default NavigationComponent;
