export const HOSPITAL_SCREEN = "/app/hospital";
export const APPROVAL_SCREEN = "/app/approvals";
export const ADMIN_USER_SCREEN = "/app/adminusers";
export const LOGIN_SCREEN = "/login";
export const PATIENT_SCREEN = "/app/patients";
export const SYSTEM_ALERT = "/app/alert";
export const CLINICIAN_SCREEN = "/app/clinician";
export const PATIENT_UPLOAD_SCREEN = "/app/patientsupload";
export const MEDICAL_RECORD_SCREEN = "/app/patients/medicalrecord";
export const SYSTEM_SETTINGS_SCREEN = "/app/systemsettings";
export const MESSAGES_SCREEN = "/app/messages";
export const DASHBOARD_SCREEN = "/dashboard";
export const ACTIVE_CONSULT_SCREEN = "/dashboard/active_consults";
export const PENDING_CONSULT_SCREEN = "/dashboard/pending_consults";
export const REPORT_SCREEN = "/dashboard/reports";
export const WARDS_SCREEN = "/dashboard/wards";
export const STAFFING_SCREEN = "/dashboard/staffing";
export const PERMISSION_SCREEN = "/app/permissions";
export const USER_UPLOAD_SCREEN = "/app/approvals/providerupload";
export const NOTIFICATION_SCREEN = "/app/notifications";
export const ADMIN_APP = "adminApp";
export const DASHBOARD_APP = "dashboardApp";

export const PagesList = {
    adminApp:[HOSPITAL_SCREEN,APPROVAL_SCREEN,ADMIN_USER_SCREEN,PATIENT_SCREEN,SYSTEM_ALERT,PATIENT_UPLOAD_SCREEN,MEDICAL_RECORD_SCREEN,SYSTEM_SETTINGS_SCREEN,PERMISSION_SCREEN,MESSAGES_SCREEN,USER_UPLOAD_SCREEN,NOTIFICATION_SCREEN,CLINICIAN_SCREEN],
    dashboardApp:[DASHBOARD_SCREEN,ACTIVE_CONSULT_SCREEN,PENDING_CONSULT_SCREEN,REPORT_SCREEN,WARDS_SCREEN,STAFFING_SCREEN]
}

export const allRoutes = [CLINICIAN_SCREEN,HOSPITAL_SCREEN,APPROVAL_SCREEN,ADMIN_USER_SCREEN,PATIENT_SCREEN,SYSTEM_ALERT,PATIENT_UPLOAD_SCREEN,MEDICAL_RECORD_SCREEN,SYSTEM_SETTINGS_SCREEN,DASHBOARD_SCREEN,ACTIVE_CONSULT_SCREEN,PENDING_CONSULT_SCREEN,REPORT_SCREEN,WARDS_SCREEN,STAFFING_SCREEN,PERMISSION_SCREEN,MESSAGES_SCREEN,USER_UPLOAD_SCREEN,NOTIFICATION_SCREEN]
