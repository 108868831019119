import React, { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
  Link as NavigationLink,
} from "react-router-dom";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  Link,
  InputAdornment,
} from "@material-ui/core";
import API from "../../../api";
import {
  ACTIVE_CONSULT_SCREEN,
  ADMIN_APP,
  ADMIN_USER_SCREEN,
  APPROVAL_SCREEN,
  DASHBOARD_APP,
  DASHBOARD_SCREEN,
  HOSPITAL_SCREEN,
  LOGIN_SCREEN,
  PATIENT_SCREEN,
  PENDING_CONSULT_SCREEN,
  REPORT_SCREEN,
  STAFFING_SCREEN,
  SYSTEM_ALERT,
  WARDS_SCREEN,
} from "../../../AppConstants";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./LoginScreen.css";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";
//import  firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics"
import "firebase/firestore"
import axios from "axios"
import "firebase/functions";
import {Firebase} from "App";
import ExpiredMessage from "../../common/ExpiredMessage";
import LoginLoader from "../../common/LoginLoader";
import {permission,usersID} from "App";
import {useSetRecoilState,useRecoilState} from "recoil";
//import Password from "components/common/Password";

const {REACT_APP_apiKey,REACT_APP_authDomain,REACT_APP_databaseURL,REACT_APP_projectId,REACT_APP_storageBucket,REACT_APP_messagingSenderId,REACT_APP_appId,REACT_APP_measurementId} = process.env

var firebaseConfig = {
  apiKey: REACT_APP_apiKey,
  authDomain: REACT_APP_authDomain,
  databaseURL: REACT_APP_databaseURL,
  projectId: REACT_APP_projectId,
  storageBucket: REACT_APP_storageBucket,
  messagingSenderId: REACT_APP_messagingSenderId,
  appId: REACT_APP_appId,
  measurementId: REACT_APP_measurementId
}; 

/* var firebaseConfig = {
  apiKey: "AIzaSyDLmPi6ZB8LVzsO0GMSsAqQvEjdKEVMxIU",
  authDomain: "qa-omnicure.firebaseapp.com",
  databaseURL: "https://qa-omnicure-default-rtdb.firebaseio.com",
  projectId: "qa-omnicure",
  storageBucket: "qa-omnicure.appspot.com",
  messagingSenderId: "699680151510",
  appId: "1:699680151510:web:c072a699783faea863b677",
  measurementId: "G-H4WCYHYB47"
};  */

// const firebaseConfig = {
//   apiKey: "AIzaSyB0ciCKAxKu37vGUbl6gBNSb0fC5Ff44a0",
//   authDomain: "dev-omnicure.firebaseapp.com",
//   databaseURL: "https://dev-omnicure.firebaseio.com",
//   projectId: "dev-omnicure",
//   storageBucket: "dev-omnicure.appspot.com",
//   messagingSenderId: "462063129427",
//   appId: "1:462063129427:web:d1fb129ea379d30441c7a6",
//   measurementId: "G-7CMWQ1D0HX"
// };

/* const firebaseConfig = {
  apiKey: "AIzaSyB0ciCKAxKu37vGUbl6gBNSb0fC5Ff44a0",
  authDomain: "dev-omnicure.firebaseapp.com",
  databaseURL: "https://dev-omnicure.firebaseio.com",
  projectId: "dev-omnicure",
  storageBucket: "dev-omnicure.appspot.com",
  messagingSenderId: "462063129427",
  appId: "1:462063129427:web:d1fb129ea379d30441c7a6",
  measurementId: "G-7CMWQ1D0HX"
}; */
  // Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// firebase.analytics();

function LoginScreen({
  alert,
  userId,
  setUserId,
  app,
  setApp,
  setUserType,
  userType,
  setUserDetails,
  tokenId,
  setTokenId,
  refreshId,
  setRefreshId,
  /* setUserEmail,
  setUserPassword */
  userPermission,
  setUserPermission,
  setFirebaseId
}) {
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [appName, setAppName] = useState("Select a function");
  const [password, setPassword] = useState("");
  const [loginAlert, setLoginAlert] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [firebaseToken, setFirebaseToken] = useState("")
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [open, setOpen] = useState(true);
  const mobileScreen = useMediaQuery('(max-width:636px)');
  const [expiredMsg, setExpiredMsg] = useState(false);
  const [expiredLogin, setexpiredLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsgEmail, setErrorMsgEmail] = useState("");
  const [errorMsgPassword, setErrorMsgPassword] = useState("");
  const setPermission = useSetRecoilState(permission)
  const setUsersID = useSetRecoilState(usersID)
  const { from } = location.state || { from: null };
  const [firebase] = useRecoilState(Firebase)
  const ifPathPartOfApp = (path, app) => {
    if (!path) return false;
    let result;
    switch (app) {
      case ADMIN_APP:
        result =
          userType === "T"
            ? [PATIENT_SCREEN].includes(path)
            : [
                HOSPITAL_SCREEN,
                ADMIN_USER_SCREEN,
                APPROVAL_SCREEN,
                PATIENT_SCREEN,
                SYSTEM_ALERT,
              ].includes(path);
        break;
      case DASHBOARD_APP:
        result = [
          DASHBOARD_SCREEN,
          ACTIVE_CONSULT_SCREEN,
          REPORT_SCREEN,
          WARDS_SCREEN,
          PENDING_CONSULT_SCREEN,
          STAFFING_SCREEN,
        ].includes(path);
        break;
      default:
        result = false;
    }
    return result;
  };
  const fromLocation = ifPathPartOfApp(from, app)
    ? from
    : {
        pathname:
          app === ADMIN_APP
            ? userType === "T"
              ? PATIENT_SCREEN
              : HOSPITAL_SCREEN
            : DASHBOARD_SCREEN,
      };

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  useEffect(() => {
    // if (!ifPathPartOfApp(fromLocation.pathname, app)) {
    //   setUserId(null);
    // } else {
      if (userId != null) {
        history.replace({pathname:'/homepage'});
      }
    // }
  }, [userId, app]);

 /*  const login = () => {
    const userEmail = email.toLowerCase();
    console.log(userEmail);

    API.post("/adminUserEndpoints/v1/adminLogin", {
      email: userEmail,
      password,
      // send the app name too
    })
      .then((res) => {
        if (res.data.status === true) {
          res.data.adminUser &&
            res.data.adminUser.userType &&
            setUserType(res.data.adminUser.userType);
          const { firstName, lastName } = res.data.adminUser;
          //setApp(appName);
          setUserId(email);
          setUserDetails({ firstName, lastName });
        } else {
          setOpen(true);
          // alert(res.data.errorMessage)
          setLoginAlert(res.data.errorMessage);
        }
      })
      .catch(() => {
        alert("System Error, Please Try Again");
      });
  }; */
  const refreshToken = () => {
    API.post("/userEndpoints/v1/renewIdToken",{
      "token": refreshId
    })
    .then((res)=>{
      setTokenId(res.data.idToken)
      if(!res.data.idToken){
        setExpiredMsg(true)
        if(expiredLogin){
          setUserId(null)
          history.push("/")
        }
      }
    })
    .catch((error) =>{})
  }
  const login = () => {
    setLoading(true);
    let token = ''
    const userEmail = email.toLowerCase();
    /* setUserEmail(userEmail)
    setUserPassword(password) */
    setErrorMsgEmail('')
    setErrorMsgPassword('')
    const pass = "/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/"
    if(email && email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)){
      setErrorMsgEmail('')
      if(password && password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)){
        setErrorMsgPassword('')
        firebase.auth().signInWithEmailAndPassword(userEmail, password)
          .then((res) => {
            console.log(res.user.refreshToken)
            setRefreshId(res.user.refreshToken)
            firebase.auth().currentUser.getIdToken( true).then(function(idToken) {
              // Send token to your backend via HTTPS
              // ...
              setTokenId(idToken)
              setFirebaseToken(idToken)
              token= idToken;
              if(idToken){
                  API.post("/userEndpoints/v1/adminLogin", {
                    email: userEmail,
                    password: password
                      // send the app name too
                  },
                  {
                    headers: {
                      'Authorization': token,
                    },
                  })
                  .then((res) => {
                    if (res.data.status === true) {
                      setLoading(false);
                      res.data.user &&
                      res.data.user.userType &&
                      setUserType(res.data.user.userType);
                      const { fname, lname, firebaseUid } = res.data.user;
                      setUserId(email);
                      setFirebaseId(firebaseUid);
                      setUserDetails({ fname, lname });
                      res.data.user.roles && setUserPermission("active");
                      //setUserAccessId(res.data.user.id)
                      setPermission(res.data.user.roles.map(({name})=>name))
                      setUsersID(res?.data?.user?.id)
                    } else {
                      setLoading(false);
                      setOpen(true);
                      setLoginAlert(res.data.errorMessage);
                    }
                  })
                  .catch( (error)=> {
                    setLoading(false);
                    if ((error.response && error.response.status === 703) ||(error.response && error.response.status === 704)) {
                      refreshToken();
                    } else if(error.response && error.response.status === 504){
                      refreshToken();
                      setLoginAlert('Server did not response please try again...')
                    } else if(error.response && error.response.status === 500){
                      refreshToken();
                      setLoginAlert('')
                    } else if(error.response && error.response.status === 502){
                      refreshToken();
                    }
                  });
                }
            }).catch((error) => {
              setLoading(false);
              // Handle error
            });
          })
          .catch((error)=> {
            setLoading(false);
            if (error && error.code === 'auth/user-not-found') {
              setLoginAlert("Email not found, enter valid email");
            } else if(error && error.code === 'auth/wrong-password'){
              setLoginAlert("Invalid password, enter valid password");
            }
          });
        } else{
          setLoading(false);
          setErrorMsgPassword(<span style={{fontSize:"10px", color:"red"}}>Minimum eight characters, at least one uppercase letter, <br/> one lowercase letter, one number and one special character</span>)
        }
      } else{
        setLoading(false);
        setErrorMsgEmail(<span style={{fontSize:"10px", color:"red"}}>Enter Valid Email</span>)
      }
  };
  const forgetPassword = () =>{
    history.push("/app/forgotpassword")
  }
  return (
    <div>
      {loginAlert !== "" && (
        <div
          style={{ fontSize: "18px", color: "#fb1b1b", padding: "0 0 10px" }}
        >
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {loginAlert}
            </Alert>
          </Collapse>
        </div>
      )}
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <div
          style={{
            margin: "auto",
            backgroundColor: "white",
            justifyContent: "space-evenly",
            display: "flex",
            flexDirection: "column",
            ...mobileScreen?{
              width:'90%',
              height:'70vh',
              padding:'0 15px'
            }:{
              padding: "50px",
              minWidth: "400px",
              minHeight: 420
            }
          }}
        >
          <h6
            style={{
              marginBottom: "20px",
              color: "grey",
              fontSize: "18px",
            }}
          >
            Welcome to Omnicure Command Center
          </h6>
          <div style={{display:'grid',gridTemplateRows:'auto auto',rowGap:7}}>
          <TextField
            style={{ marginBottom: "20px" }}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            label="Email"
            variant="outlined"
            helperText={errorMsgEmail}
            id="usernameInput"
          />
          <TextField
            style={{ marginBottom: "20px" }}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            label="Password"
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            variant="outlined"
            helperText={errorMsgPassword}
            id="passwordInput"
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    color="primary"
                  >
                    {showPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                  </IconButton>
                  {/*  <LightTooltip title="Atleast one uppercase and lowercase Letter, one number, one special character.">
                                      <InfoOutlinedIcon
                                        style={{ color: "#9b51e0 !important" }}
                                      ></InfoOutlinedIcon>
                                    </LightTooltip> */}
                </InputAdornment>
              ),
            }}
          />
          <Link onClick={forgetPassword} style={{fontSize:"12px"}}>
              Forgot Password
            </Link>
          </div>
          {/*<FormControl
            variant="outlined"
            style={{ width: 175, marginBottom: "20px" }}
          >
            <Select
              value={appName}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(event) => setAppName(event.target.value)}
            >
              <MenuItem value={"Select a function"}>
                {" "}
                Select a function{" "}
              </MenuItem>
              <MenuItem value={ADMIN_APP}> Administration </MenuItem>
              <MenuItem value={DASHBOARD_APP}>View Dashboard </MenuItem>
            </Select>
          </FormControl>*/}

          <Button
            color="primary"
            variant="contained"
            onClick={login}
            id="signInButton"
          >
            Sign In
          </Button>
          {loading && <LoginLoader />}
          {/* <div className="hrline">or</div>
                        <NavigationLink to="/app/sign-up"><Button style={{width: 'fit-content',alignSelf:'center',marginBottom:'20px'}} color="primary" variant="contained">Sign Up</Button></NavigationLink>
                        <NavigationLink to="/app/forgetPassword"><Link component="button" underline="hover" style={{fontSize:'14px',color:'#808080'}}>Forgot Password ?</Link></NavigationLink> */}
        </div>
      </div>

      {expiredMsg && <div><ExpiredMessage expiredMsg={expiredMsg} setexpiredLogin={setexpiredLogin}/></div>}
    </div>
  );
}

export default LoginScreen;
