import React, { useEffect, useState } from "react";
import {useHistory, useLocation,  Link as NavigationLink} from "react-router-dom";
import {TextField, Button, Select, MenuItem, FormControl, Link, InputAdornment} from "@material-ui/core";
import API from "../../../api";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./LoginScreen.css";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ExpiredMessage from "../../common/ExpiredMessage";
import LoginLoader from "../../common/LoginLoader";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import axios from 'axios';

const ForgotPasswordScreen = (props) =>{
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [generateOtpStatus, setGenerateOtpStatus] = useState(true)
  const [submitOtpStatus, setSubmitOtpStatus] = useState(false)
  const [changePasswordStatus, setChangePasswordStatus] = useState(false)
  const [successMsg, setSuccessMsg] = useState(false)
  const [open, setOpen] = useState(true);
  const [loginAlert, setLoginAlert] = useState("");
  const mobileScreen = useMediaQuery('(max-width:636px)');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsgEmail, setErrorMsgEmail] = useState("")
  const [errorMsgOtp, setErrorMsgOtp] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
  const history = useHistory();
  const [expiredMsg, setExpiredMsg] = useState(false);
  const [expiredLogin, setexpiredLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const retryApiGet = (url,header)=>{
    return API.get(url,header).catch(function(error){console.log(error,error.response,"Retry Fetch");if([401,500,502,504,703,704].includes(error?.response?.status)){
      return new Promise(resolve=>{
        API.post("/userEndpoints/v1/renewIdToken",{
          "token": props.refreshId
        })
        .then((res)=>{
          props.setTokenId(res.data.idToken)
          if(!res.data.idToken){
            setExpiredMsg(true)
            if(expiredLogin){
              props.setUserId(null)
              history.push("/")
            }
            throw error
          }else{
            resolve(API.get(url,{headers:{
              Authorization:res.data.idToken
            }}))
          }
        })
        .catch((error) =>{Promise.reject(error)})
      })
    }})
  }
  
  const retryApiPost = (url,data,header)=>{
    return API.post(url,data,header).catch(function(error){console.log(error,error.response,"Retry Fetch");if([401,500,502,504,703,704].includes(error?.response?.status)){
      return new Promise(resolve=>{
        API.post("/userEndpoints/v1/renewIdToken",{
          "token": props.refreshId
        })
        .then((res)=>{
          props.setTokenId(res.data.idToken)
          if(!res.data.idToken){
            setExpiredMsg(true)
            if(expiredLogin){
              props.setUserId(null)
              history.push("/")
            }
            throw error
          }else{
            resolve(API.post(url,data,{headers:{
              Authorization:res.data.idToken
            }}))
          }
        })
        .catch((error) =>{Promise.reject(error)})
      })
    }})
  }

  const refreshToken = () => {
    API.post("/userEndpoints/v1/renewIdToken",{
      "token": props.refreshId
    })
    .then((res)=>{
      props.setTokenId(res.data.idToken)
     if(!res.data.idToken){
        setExpiredMsg(true)
        if(expiredLogin){
          props.setUserId(null)
          history.push("/")
        }
      }
    })
    .catch((error) =>{})
  }
  useEffect(() =>{
    if(expiredLogin){
      props.setUserId(null)
      history.push("/")
    }
  },[expiredLogin])
// useEffect(()=>{
//   const refreshAuthLogic = failedRequest =>
//   API.post("/userEndpoints/v1/renewIdToken",{   
//     "token": props.refreshId
//   })
//   .then((res)=>{
//     props.setTokenId(res.data.idToken)
//     if(!res.data.idToken){
//        setExpiredMsg(true)
//        if(expiredLogin){
//          props.setUserId(null)
//          history.push("/")
//          return Promise.reject();
//        }
//      }        
//     failedRequest.response.config.headers["Authorization"] = res.data.idToken;
//   return Promise.resolve();
//   })
//   .catch((error) =>{
//     console.log("refresh fail");
//     return Promise.reject(error);
//   })
//   createAuthRefreshInterceptor(API, refreshAuthLogic,{statusCodes:[401,500,502,504,703,704]});
// },[])

  const generateotp = () => {
    const mail ="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
    if(email !== '' && email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)){
      setLoading(true);
      setErrorMsgEmail("")
      retryApiPost("/userEndpoints/v1/forgot/password",{
        "email": email
      },null)
      .then((res) => {
        setLoading(false);
        if(res.data && res.data.status !== false){
          setGenerateOtpStatus(false)
          setSubmitOtpStatus(true)
        }else {
          setErrorMsgEmail(<span style={{fontSize:"12px", color:"red"}}>{res.data.errorMessage}</span>)
        }
      })
      .catch((error) =>{
        setLoading(false);
      /*  refreshToken(); */
      if ((error.response && error.response.status === 703) ||(error.response && error.response.status === 704)) {
        //refreshToken();
      } else if(error.response && error.response.status === 504){
        //refreshToken();
        setLoginAlert('Server did not response please try again...')
      } else if(error.response && error.response.status === 500){
        //refreshToken();
        setLoginAlert('')
      } else if(error.response && error.response.status === 502){
        //refreshToken();
      }
      });
    } else{
      setErrorMsgEmail(<span style={{fontSize:"10px", color:"red"}}>Enter Valid Email</span>)
    }
  }
  const submitotp = () => {
    const mail ="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
      setErrorMsgEmail("")
      setErrorMsgOtp("")
    if(otp !== '' && otp.match(/^[0-9]\d{0,3}$/)){
      setErrorMsgOtp("")
      if(email !== '' && email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)){
        setLoading(true);
        retryApiPost("/userEndpoints/v1/forgot/password/otp/verify",{
          "email": email,
          "otp":otp
        },null)
        .then((res) => {
          setLoading(false);
          if(res.data.status && res.data.status !== false){
            setSubmitOtpStatus(false)
            setChangePasswordStatus(true)
          } else{
            setErrorMsgOtp(<span style={{fontSize:"10px", color:"red"}}>{res.data.errorMessage}</span>)
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error&&(error.response && error.response.status === 703) ||(error.response && error.response.status === 704)) {
            //refreshToken();
          } else if(error&&error.response && error.response.status === 504){
            //refreshToken();
            setLoginAlert('Server did not response please try again')
          } else if(error&&error.response && error.response.status === 500){
            //refreshToken();
            setLoginAlert('')
          } else if(error&&error.response && error.response.status === 502){
            //refreshToken();
          }
        /*  refreshToken(); */
        })
      } else{
        setErrorMsgEmail(<span style={{fontSize:"10px", color:"red"}}>Enter Valid Email</span>)
      }
    } else{
      setErrorMsgOtp(<span style={{fontSize:"10px", color:"red"}}>Enter Valid Otp and maximum four digits</span>)
    }
  }
  const changePassword = () => {
    setErrorMsgEmail("")
    setErrorMsg("")
    const pass = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
    const mail ="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
    if(email !== ''&& email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)){
      setErrorMsgEmail("")
      if(newPassword && newPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)){
        setLoading(true);
        setErrorMsg("")
        setSuccessMsg(false)
        retryApiPost("/userEndpoints/v1/set/new/password",{
          "email": email,
          "passwordNew": newPassword
        },null)
        .then((res)=>{
          console.log('....res...', res)
          setChangePasswordStatus(false)
          setSuccessMsg(true)
          setLoading(false);
        })
        .catch((error)=>{
          setLoading(false);
          /* refreshToken(); */
          if (error&&(error.response && error.response.status === 703) ||(error.response && error.response.status === 704)) {
            //refreshToken();
          } else if(error&&error.response && error.response.status === 504){
            //refreshToken();
            setLoginAlert('Server did not response please try again...')
          } else if(error&&error.response && error.response.status === 500){
            //refreshToken();
            setLoginAlert('')
          } else if(error&&error.response && error.response.status === 502){
            //refreshToken();
          }
        })
      }else {
        setErrorMsg(<span style={{fontSize:"10px", color:"red"}}>Minimum eight characters, at least one uppercase letter, <br/> one lowercase letter, one number and one special character</span>)
      }
    } else{
      setErrorMsgEmail(<span style={{fontSize:"10px", color:"red"}}>Enter Valid Email</span>)
    }
  }
  const login = () =>{
    history.push("/login")
  }
  return (
    <div>
      {loginAlert !== "" && (
        <div
          style={{ fontSize: "18px", color: "#fb1b1b", padding: "0 0 10px" }}
        >
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {loginAlert}
            </Alert>
          </Collapse>
        </div>
      )}
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <div
          style={{
            margin: "auto",
            backgroundColor: "white",
            justifyContent: "space-evenly",
            display: "flex",
            flexDirection: "column",
            ...mobileScreen?{
              width:'90%',
              height:'70vh',
              padding:'0 15px'
            }:{
              padding: "50px",
              minWidth: "400px",
              minHeight: 420
            }
          }}
        >
          <h6 style={{marginBottom: "0px", color: "grey", fontSize: "18px",}}>
            {generateOtpStatus? 'Generate OTP' : submitOtpStatus? 'Submit OTP': changePasswordStatus? 'Create New Password' : ''}
          </h6>
          <div style={{display:'grid',gridTemplateRows:'auto auto',rowGap:7}}>
         {!successMsg && <TextField
            style={{ marginBottom: "10px" }}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            label="Email"
            variant="outlined"
            helperText={errorMsgEmail}
          />}
          {submitOtpStatus &&<TextField
            style={{ marginBottom: "0px" }}
            value={otp}
            onChange={(event) => setOtp(event.target.value)}
            label="OTP"
            variant="outlined"
            helperText={errorMsgOtp}
          />}
         {changePasswordStatus && <TextField
            style={{ marginBottom: "0px" }}
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
            label="New Password"
            variant="outlined"
            helperText={errorMsg}
            type={showPassword ? "text" : "password"}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    color="primary"
                  >
                    {showPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />}
          </div>
          {successMsg && <div>
            <span style={{fontSize: "16px", color:"#9b51e0"}}>
              Password Successfully Changed...
              <Link onClick={login} style={{fontSize:"16px", color: "#8dd7f6", textDecoration: "underline", cursor:"pointer"}}>
              Login
            </Link>
            </span>
          </div>}
         {/*  {errorMsg &&<div>
            <p style={{fontSize:"10px", color:"red"}}>{errorMsg}</p>
          </div>} */}
         {generateOtpStatus && <Button color="primary" variant="contained" onClick={generateotp}>
            Submit
            </Button>}
         {submitOtpStatus && <Button color="primary" variant="contained" onClick={submitotp}>
           Submit
           </Button>}
          {changePasswordStatus &&<Button color="primary" variant="contained" onClick={changePassword}>
            Submit
          </Button>}
          {!successMsg && <div>
              <Link onClick={login} style={{fontSize:"16px", color: "#8dd7f6", textDecoration: "underline", cursor:"pointer"}}>
              Back to login
            </Link>
          </div>}
          {loading && <LoginLoader />}
        </div>
      </div>
      {expiredMsg && <div><ExpiredMessage expiredMsg={expiredMsg} setexpiredLogin={setexpiredLogin}/></div>}
    </div>
  );
};
export default ForgotPasswordScreen;
